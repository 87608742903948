<template>
  <div class="">
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12 mt-2" style="padding-left: 50px; padding-right: 50px">
          <PageTitle title="SETTLEMENT" />
          <form class="form-inline transparent-form p10 border-page m-t-2"
            v-if="role == 'SUPER_MASTER' || role == 'ADMIN' || role == 'SUPER_ADMIN'">
            <div class="col-lg-12 row">
              <div class="col-lg-12 text-right" v-if="role == 'SUPER_MASTER' || role == 'ADMIN' || role == 'SUPER_ADMIN'">
                <!-- <button v-if="role == 'ADMIN'" id="btn-closing" type="button" v-on:click="openClosingPrice()"
                  class="btn btn-primary">
                  Closing Price
                </button> -->

                <button v-if="role == 'SUPER_MASTER'" id="btn-search" type="button"
                  v-on:click="showSettlementPopup('MCX', serverCode)" class="btn btn-primary">
                  MCX Settlement
                </button>
                &nbsp;
                <button v-if="role == 'SUPER_MASTER'" id="btn-search" type="button"
                  v-on:click="showSettlementPopup('NSE', serverCode)" class="btn btn-info">
                  NSE Settlement
                </button>
                <button id="btn-search" type="button" v-on:click="showSplitPopup()" class="btn btn-success ml-2">
                  SPLIT
                </button>
                <button id="brokerage-button" type="button" v-if="role == 'SUPER_MASTER'"
                  style="background-color: darkorange" v-on:click="updateBrokerage(serverCode)" class="btn ml-2">
                  UPDATE BROKERAGE
                </button>

                <button id="btn-search" type="button" v-on:click="showDividentPopup()" class="btn btn-warning ml-2">
                  DIVIDENT
                </button>
                <button id="btn-search" type="button" v-on:click="showClosePostionPopup()" class="btn btn-danger ml-2">
                  FORCE CLOSE POSITION
                </button>
                <button id="btn-import" type="button" onclick="document.getElementById('tradeImportFile').click()"
                  class="btn btn-secondary ml-2">
                  TRADE IMPORT
                </button>
                <input type="file" v-show="false" id="tradeImportFile" v-on:change="onSelectFile($event)" />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row" style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <table id="table" style="margin-top: 16px" data-toggle="table" data-search="true" data-filter-control="true"
            data-toolbar="#toolbar" class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Name</th>
                <th class="head">Login ID</th>
                <th class="head">Server Codde</th>
                <th class="head">MCX Last Settlement</th>
                <th class="head">NSE Last Settlement</th>
                <th class="head" v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'">Action</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body">
                  {{ data.name ? $helperService.getUpperCase(data.name) : "" }}
                </td>
                <td class="body">{{ data.code }}</td>
                <td class="body">{{ data.server_code }}</td>
                <td class="body">
                  {{
                    data.mcx_last_settlement
                    ? $helperService.getDate(data.mcx_last_settlement)
                    : "-"
                  }}
                </td>
                <td class="body">
                  {{
                    data.nse_last_settlement
                    ? $helperService.getDate(data.nse_last_settlement)
                    : "-"
                  }}
                </td>

                <td class="body" style="padding: 10px" v-if="role == 'ADMIN' || role == 'SUPER_ADMIN'">
                  <button id="btn-search" type="button" v-on:click="showSettlementPopup('MCX', data.server_code)"
                    class="btn btn-primary">
                    MCX Settle
                  </button>
                  &nbsp;
                  <button id="btn-search" type="button" v-on:click="showSettlementPopup('NSE', data.server_code)"
                    class="btn btn-info">
                    NSE Settle
                  </button>
                  <button id="btn-search" type="button" v-on:click="showSplitPopup(data.server_code)"
                    class="btn btn-success ml-2">
                    Split
                  </button>
                  <button :id="'brokerage-button_' + index" type="button" style="background-color: darkorange" v-on:click="
                    updateBrokerage(
                      data.server_code,
                      'brokerage-button_' + index
                    )
                    " class="btn ml-2">
                    Update Brokerage
                  </button>

                  <button id="btn-search" type="button" v-on:click="showDividentPopup(data.server_code)"
                    class="btn btn-warning ml-2">
                    Divident
                  </button>
                  <button id="btn-search" type="button" v-on:click="showClosePostionPopup(data.server_code)"
                    class="btn btn-danger ml-2">
                    Force Close Position
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LargeTextModel ref="largeTextModel">
      <div class="modal-header pt-0">
        <h5 class="modal-title">{{ type }} SETTLEMENT</h5>
        <button type="button" class="close" v-on:click="$refs.largeTextModel.closeModal">
          <span aria-hidden="true" style="color: white;">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <Form ref="settlementForm" @submit="adminsettleMent()">
          <div class="col-lg-12 col-sm-12 row mb-3 mt-4" style="margin: 120px">
            <div class="col-lg-5">
              <label>LIVE DATA</label>
            </div>
            <div class="col-lg-3 form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" true-value="LIVE"
                style="width: 37px; height: 19px" false-value="BBB" v-model="status" v-on:change="updateData(status)"
                id="switch_1" />
              <label class="form-check-label" for="switch_1"> </label>
            </div>
          </div>

          <div class="col-lg-12 col-sm-12 row mb-3 mt-3" style="margin: 120px">
            <div class="col-lg-5">
              <label>BHAVCOPY DATA</label>
            </div>
            <div class="col-lg-2 form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" true-value="BHAVCOPY"
                style="width: 37px; height: 19px" false-value="AAAA" v-on:change="updateData(status)" v-model="status"
                id="switch_1" />
              <label class="form-check-label" for="switch_1"> </label>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 input-group mb-3" style="justify-content: center" v-if="status == 'BHAVCOPY'">
            <div class="col-lg-8 col-sm-12 custom-file">
              <input type="file" class="custom-file-input" id="inputGroupFile04" v-on:change="onChange($event)" />
              <label class="custom-file-label" style="text-align: left" for="inputGroupFile04">{{ docFile ? docFile.name :
                "Choose File" }}</label>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 row">
            <div class="col-8">
              Bhavcopy Date :
              {{ settlmentDate ? settlmentDate : "" }}
            </div>
            <button class="save-btn col-4" style="margin-top: 0px !important" id="document-button">
              Settle
            </button>
          </div>

          <div style="text-align: end">
            <input type="checkbox" v-on:change="updateList" v-model="isZero" class="ml-1" />
            <label style="margin-left: 5px"> Zero Close Price </label>
          </div>

          <div class="" style="height: 332px; overflow-y: scroll" v-if="isShow">
            <table id="table" data-toggle="table" data-search="true" data-filter-control="true" data-toolbar="#toolbar"
              style="
                width: 70%;
                justify-content: center;
                display: inline-table;
                margin-left: 60px;
              " class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">Script</th>
                  <th class="head" id="id-close">{{ status }} Close Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in finalpreviewList" :key="index">
                  <td class="body">
                    <div style="padding-top: 8px">
                      {{ data.name }}-{{ getExpiry(data.expiry) }}
                    </div>
                  </td>
                  <td class="body">
                    <Field :id="'close_price_' + index" type="number" class="form-control" :name="'close_price_' + index"
                      placeholder="Closing Price" v-model="data.close" rules="required: close price"
                      :validateOnInput="true" />
                    <ErrorMessage :name="'close_price_' + index" class="text-danger" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Form>
      </div>
    </LargeTextModel>
    <LargeTextModel ref="supermastersettle">
      <div class="modal-header pt-0">
        <h5 class="modal-title">{{ type }} SETTLEMENT</h5>
        <button type="button" class="close" v-on:click="$refs.supermastersettle.closeModal">
          <span aria-hidden="true" style="color: white;">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <Form ref="supermaster" @submit="adminsettleMent()">
          <div class="col-lg-12 col-sm-12 row mb-3 mt-4" style="margin: 120px">
            <div class="col-lg-5">
              <label>LIVE DATA</label>
            </div>
            <div class="col-lg-3 form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" true-value="LIVE"
                style="width: 37px; height: 19px" false-value="BBB" v-on:change="updateData(status)" v-model="status"
                id="switch_1" />
              <label class="form-check-label" for="switch_1"> </label>
            </div>
          </div>

          <div class="col-lg-12 col-sm-12 row mb-3 mt-3" style="margin: 120px">
            <div class="col-lg-5">
              <label>BHAVCOPY DATA</label>
            </div>
            <div class="col-lg-2 form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" true-value="BHAVCOPY"
                style="width: 37px; height: 19px" false-value="AAAA" v-model="status" v-on:change="updateData(status)"
                id="switch_1" />
              <label class="form-check-label" for="switch_1"> </label>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 input-group mb-3" style="justify-content: center" v-if="status == 'BHAVCOPY'">
            <div class="col-lg-8 col-sm-12 custom-file">
              <input type="file" class="custom-file-input" id="inputGroupFile04" v-on:change="onChange($event)" />
              <label class="custom-file-label" style="text-align: left" for="inputGroupFile04">{{ docFile ? docFile.name :
                "Choose File" }}</label>
            </div>
          </div>
          <div class="row col-12">
            <div class="col-8">
              Bhavcopy Date :
              {{ settlmentDate ? settlmentDate : "" }}
            </div>

            <button class="save-btn col-4" style="margin-top: 0px" id="supermastersettle-button">
              Settle
            </button>
          </div>
          <div class="" style="height: 332px; overflow-y: scroll" v-if="isShow">
            <table id="table" data-toggle="table" data-search="true" data-filter-control="true" data-toolbar="#toolbar"
              style="
                width: 70%;
                justify-content: center;
                display: inline-table;
                margin-left: 60px;
              " class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">Script</th>
                  <th class="head" id="id-close">Close Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in finalpreviewList" :key="index">
                  <td class="body" style="padding-top: 8px">{{ data.name }}</td>
                  <td class="body">
                    <Field :id="'close_price_' + index" type="number" class="form-control" :name="'close_price_' + index"
                      placeholder="Closing Price" v-model="data.close" rules="required: close price"
                      :validateOnInput="true" />
                    <ErrorMessage :name="'close_price_' + index" class="text-danger" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Form>
      </div>
    </LargeTextModel>

    <TextModal ref="closingPrice">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Closing Price</h5>
        <button type="button" class="close" v-on:click="$refs.closingPrice.closeModal">
          <span aria-hidden="true" style="color: white;">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <Form ref="supermaster" @submit="adminsettleMent()">
          <div class="col-lg-12 col-sm-12 row mb-3 mt-3" style="margin: 120px">
            <div class="col-lg-5">
              <label>BHAVCOPY DATA</label>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 input-group mb-3" style="justify-content: center">
            <div class="col-lg-8 col-sm-12 custom-file">
              <input type="file" class="custom-file-input" id="inputGroupFile04" v-on:change="onChange($event)" />
              <label class="custom-file-label" style="text-align: left" for="inputGroupFile04">{{ docFile ? docFile.name :
                "Choose File" }}</label>
            </div>
          </div>
          <div class="row col-12">
            <div class="col-8">
              Bhavcopy Date :
              {{ settlmentDate ? settlmentDate : "" }}
            </div>

            <button class="save-btn col-4" style="margin-top: 0px" id="closing-button">
              Upload
            </button>
          </div>
        </Form>
      </div>
    </TextModal>

    <TextModal ref="split">
      <div class="modal-header pt-0">
        <h5 class="modal-title">SPLIT</h5>
        <button type="button" class="close" v-on:click="$refs.split.closeModal">
          <span aria-hidden="true" style="color: white;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0 !important">
        <Form ref="splitForm" @submit="split()">
          <div class="col-lg-12 col-sm-12 row mb-3 mt-4">
            <div class="col-lg-12">
              <label class="col-form-label ml-1 mt-0"> Script Name </label>
              <Field type="text" name="name" placeholder="SCRIPT" rules="required: script" v-model="splitobj.name"
                :validateOnInput="true" v-slot="{ field }" v-bind="field">
                <Multiselect mode="single" trackBy="name" label="name" valueProp="name" class="register-select"
                  placeholder="SCRIPT" v-model="splitobj.name" :options="scriptList" searchable="true" />
                <ErrorMessage name="name" class="text-danger" />
              </Field>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label ml-1 mt-0">Previous QTY Per Lot</label>

              <Field type="text" class="form-control" name="previous_qty" placeholder="Previous QTY"
                v-model="splitobj.previous_qty" rules="required:previous quantity|min:@new_qty" :validateOnInput="true" />
              <ErrorMessage name="previous_qty" class="text-danger" />
            </div>
            <div class="col-lg-6">
              <label class="col-form-label ml-1 mt-0">New QTY Per Lot</label>
              <Field type="text" class="form-control" name="new_qty" placeholder="New QTY" v-model="splitobj.new_qty"
                rules="required:new quantity|max:@previous_qty" :validateOnInput="true" />
              <ErrorMessage name="new_qty" class="text-danger" />
            </div>
            <div class="col-lg-6">
              <label class="col-form-label ml-1 mt-0">Last Closing Price</label>

              <Field type="text" class="form-control" name="close_price" placeholder="Last Closing Price"
                v-model="splitobj.close_price" rules="required: close price" :validateOnInput="true" />
              <ErrorMessage name="close_price" class="text-danger" />
            </div>
            <div class="col-lg-6">
              <label class="col-form-label ml-1 mt-0">New Open Price</label>
              <Field type="text" class="form-control" name="new_price" placeholder="New Open Price"
                v-model="splitobj.new_price" rules="required: close price" :validateOnInput="true" />
              <ErrorMessage name="new_price" class="text-danger" />
            </div>
          </div>
          <div class="pb-0 text-center">
            <button class="save-btn" id="split-button">Submit</button>
          </div>
        </Form>
      </div>
    </TextModal>
    <TextModal ref="divident">
      <div class="modal-header pt-0">
        <h5 class="modal-title">DIVIDENT</h5>
        <button type="button" class="close" v-on:click="$refs.divident.closeModal">
          <span aria-hidden="true" style="color: white;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0 !important">
        <Form ref="dividentForm" @submit="divident()">
          <div class="col-lg-12 col-sm-12 row mb-3 mt-4">
            <div class="col-lg-12">
              <label class="col-form-label ml-1 mt-0"> Script Name </label>
              <Field type="text" name="name" placeholder="SCRIPT" rules="required: script" v-model="dividentobj.name"
                :validateOnInput="true" v-slot="{ field }" v-bind="field">
                <Multiselect mode="single" trackBy="name" label="name" valueProp="name" class="register-select"
                  placeholder="SCRIPT" v-model="dividentobj.name" :options="scriptList" searchable="true" />
                <ErrorMessage name="name" class="text-danger" />
              </Field>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label ml-1 mt-0">Last Closing Price</label>

              <Field type="text" class="form-control" name="close_price" placeholder="Last Closing Price"
                v-model="dividentobj.close_price" rules="required: close price" :validateOnInput="true" />
              <ErrorMessage name="close_price" class="text-danger" />
            </div>
            <div class="col-lg-6">
              <label class="col-form-label ml-1 mt-0">New Open Price</label>
              <Field type="text" class="form-control" name="new_price" placeholder="New Open Price"
                v-model="dividentobj.new_price" rules="required: close price" :validateOnInput="true" />
              <ErrorMessage name="new_price" class="text-danger" />
            </div>
          </div>

          <div class="pb-0 text-center">
            <button class="save-btn" id="divident-button">Submit</button>
          </div>
        </Form>
      </div>
    </TextModal>
    <TextModal ref="closePosition">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Close Position</h5>
        <button type="button" class="close" v-on:click="$refs.closePosition.closeModal">
          <span aria-hidden="true" style="color: white;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0 !important">
        <Form ref="closePositionForm" @submit="closePosition()">
          <div class="col-lg-12 col-sm-12 row mb-3 mt-4">
            <div class="col-lg-12 col-sm-12 mt-1">
              <label class="col-form-label ml-1 mt-0">Exchange</label>

              <Multiselect mode="single" trackBy="name" label="name" valueProp="value" class="register-select"
                placeholder=" Exchange" v-model="closeObj.exchange" :options="exchanges"
                @select="closeScript(closeObj.exchange)" searchable="true" />
            </div>
            <div class="col-lg-12">
              <label class="col-form-label ml-1 mt-0"> Script Name </label>
              <Field type="text" name="name" placeholder="SCRIPT" rules="required: script"
                v-model="closeObj.instrument_token" :validateOnInput="true" v-slot="{ field }" v-bind="field">
                <Multiselect mode="single" trackBy="instrument_token" label="trading_symbol" valueProp="instrument_token"
                  class="register-select" placeholder="SCRIPT" v-model="closeObj.instrument_token"
                  :options="closeScriptList" searchable="true" />
                <ErrorMessage name="name" class="text-danger" />
              </Field>
            </div>
            <div class="col-lg-12">
              <label class="col-form-label ml-1 mt-0">Closing Price</label>

              <Field type="text" class="form-control" name="close_price" placeholder="Closing Price"
                v-model="closeObj.close_price" rules="required: close price"
                v-on:keypress="$helperService.allowDecimalValue($event)" :validateOnInput="true" />
              <ErrorMessage name="close_price" class="text-danger" />
            </div>
          </div>

          <div class="pb-0 text-center">
            <button class="save-btn" id="close-position-button">Submit</button>
          </div>
        </Form>
      </div>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal.vue";
import LargeTextModel from "@/components/LargeTextModel.vue";
import Multiselect from "@vueform/multiselect";
import swal from "sweetalert2";
import moment from "moment";
import PageTitle from '../../components/PageTitle'

// import Datepicker from "vue3-datepicker";
export default {
  components: {
    Form,
    ErrorComponent,
    Field,
    ErrorMessage,
    Multiselect,
    TextModal,
    PageTitle,
    LargeTextModel,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      role: localStorage.getItem("role"),
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },
      ],
      loader: true,
      isShow: false,
      req: [],
      docFile: undefined,
      dividentobj: {},
      splitobj: {},
      closeObj: {
        instrument_token: "",
        exchange: "",
        close: "",
      },
      list: [],
      zeroList: [],
      previewList: [],
      closeScriptList: [],
      fromDate: "",
      toDate: "",
      status: "BHAVCOPY",
      server_code: "",
      type: "",
      scriptList: [],
      isZero: false,
      bhavCopyDate: "",
      settlmentDate: "",
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUser?.id;
    },
    serverCode() {
      return this.$store.getters.getUser?.server_code;
    },
    finalpreviewList() {
      return this.isZero ? this.zeroList : this.previewList;
    },
  },
  mounted() {
    this.checkAccess();
    this.getList(0);
    this.getScriptName();
  },
  methods: {
     checkAccess() {
      var access = JSON.parse(localStorage.getItem("access"));
      if (access.is_settlement == 0 && this.role != 'ADMIN') {
        this.$router.push("/access-denied");
      }
    },
    openClosingPrice() {
      this.$refs.closingPrice.showModal();
    },
    getScriptName() {
      this.scriptList = [];
      this.$api
        .getAPI({
          _action: "/script-list/NFO",
        })
        .then((res) => {
          this.scriptList = res;
        })
        .catch(() => { });
    },
    updateBrokerage(server_code, btnID) {
      this.$api
        .getAPI({
          _action: "/update-brokerage?code=" + server_code,
          _buttonId: btnID ? btnID : "brokerage-button",
          _isExternal: true
        })
        .then(() => {
          this.$notify({
            type: "success",
            text: "Brokerage updated Successfully",
          });
        })
        .catch(() => { });
    },
    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "application/vnd.ms-excel" &&
        files[0].type.toLowerCase() != "text/csv"
      ) {
        alert("Invalid file formate, please use csv file. " + files[0].type);
        return;
      }

      var tmppath = URL.createObjectURL(files[0]);

      this.$refs.errorComponent.updateFormLoader(true);
      var reader = new FileReader();
      var vm = this;
      reader.onload = () => {
        var lines = reader.result.split(/[\r\n]+/g);
        for (var i in lines) {
          var temp = lines[i].split(",");
          if (vm.type == "NSE") {
            if (temp[1] == "FUTSTK" ||temp[1] == "FUTIDX") {
              console.log("FUTSTK")
              vm.req.push({
                close: temp[4],
                expiry: moment(temp[3], "DD-MMM-YYYY").format("YYYY-MM-DD"),
                name: temp[2],
              });
              vm.bhavCopyDate = moment(temp[0], "DD-MMM-YYYY").format(
                "DD-MM-YYYY"
              );
            }
          } else {
            if (temp[4] == "COF") {
              vm.req.push({
                close: temp[17],
                expiry: moment(temp[9], "YYYY-MM-DD").format("YYYY-MM-DD"),
                name: temp[7],
              });
              if (temp[7] == "GOLD" || temp[7] == "SILVER") {
                vm.req.push({
                  close: temp[17],
                  expiry: moment(temp[9], "YYYY-MM-DD").format("YYYY-MM-DD"),
                  name: "M" + temp[7],
                });
              }
              vm.bhavCopyDate = moment(temp[0], "YYYY-MM-DD").format(
                "DD-MM-YYYY"
              );
            }
          }
        }

        vm.updateData(vm.status);
        vm.$refs.errorComponent.updateFormLoader(false);
      };

      reader.readAsText(files[0]);
      vm.docFile = files[0];
      document.getElementById("inputGroupFile04").src = tmppath;
    },
    uploadClosingPrice() {
      if (!this.settlmentDate) {
        this.$notify({
          type: "error",
          text: "Please select bhav copy",
        });
        return;
      }

      this.$api
        .postAPI({
          _action:
            "/closing?date=" + this.settlmentDate,
          _body: this.previewList,
          _buttonId: "closing-button",
          _isExternal: true,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.largeTextModel.closeModal();
          this.getList(0);
        })
        .catch(() => { });


    },
    adminsettleMent() {
      if (!this.settlmentDate) {
        this.$notify({
          type: "error",
          text: "Please select bhav copy",
        });
        return;
      }

      var code = this.server_code;
      if (!code) {
        code = this.serverCode;
      }
      this.$api
        .postAPI({
          _action:
            "/settlement?exchange=" +
            this.type +
            "&date=" +
            moment(this.settlmentDate, "DD-MM-YYYY").format("YYYY-MM-DD") +
            "&user=" +
            (this.server_code ? "A" : "S") +
            "&userId=" +
            this.userId +
            "&code=" +
            code,
          _body: this.previewList,
          _buttonId: this.server_code
            ? "document-button"
            : "supermastersettle-button",
          _isExternal: true,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.largeTextModel.closeModal();
          this.getList(0);
        })
        .catch(() => { });
    },
    divident() {
      if (this.server_code) {
        this.dividentobj.server_code = this.server_code;
      }
      this.$api
        .postAPI({
          _action: "/divident",
          _body: this.dividentobj,
          _buttonId: "divident-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.divident.closeModal();
          this.getList(0);
        })
        .catch(() => { });
    },
    split() {
      if (this.server_code) {
        this.splitobj.server_code = this.server_code;
      }
      this.$api
        .postAPI({
          _action: "/split",
          _body: this.splitobj,
          _buttonId: "split-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.split.closeModal();
          this.getList(0);
        })
        .catch(() => { });
    },
    closePosition() {
      if (this.role == "SUPER_MASTER") {
        this.closeObj.server_code = this.serverCode;
      }
      if (this.server_code) {
        this.closeObj.server_code = this.server_code;
      }
      this.$api
        .postAPI({
          _action: "/non-existing-script-close-position",
          _body: this.closeObj,
          _buttonId: "close-position-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.closePosition.closeModal();
          this.getList(0);
        })
        .catch(() => { });
    },
    closeScript(exchange) {
      this.closeObj.instrument_token = "";
      var obj = {};
      obj.exchange = exchange;
      this.$api
        .getAPI({
          _action: "/close-non-existing-script-list",
          _body: obj,
        })
        .then((res) => {
          this.closeScriptList = [];
          this.closeScriptList = res;
        })
        .catch(() => { });
    },
    showSettlementPopup(type, server_code) {
      this.server_code = server_code;
      var code = server_code;
      if (!code) {
        code = this.serverCode;
      }

      this.docFile = undefined;
      this.status = "BHAVCOPY";
      this.isZero = false;
      this.$refs.errorComponent.updateFormLoader(true);
      var obj = { server_code: code };
      this.bhavCopyDate = "";
      this.settlmentDate = "";
      if (type == "NSE") {
        obj.exchange = "NFO";
      } else {
        obj.exchange = type;
      }

      this.$api
        .getAPI({
          _action: "/settlement-preview",
          _body: obj,
        })
        .then((res) => {
          this.previewList = [];
          for (var i in res) {
            this.previewList.push({
              name: res[i].name,
              expiry: res[i].expiry,
              close: 0,
              lastLtp: res[i].close,
            });
          }
          this.isShow = true;
          this.type = type;
          this.server_code = server_code;
          this.$refs.largeTextModel.showModal();
          this.$refs.settlementForm.resetForm();
          this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => { });
    },
    updateData(status) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.isShow = false;
      if (status == "LIVE") {
        this.settlmentDate = this.$helperService.getDate(
          moment(new Date()).add(-1, "days")
        );
        for (var i in this.previewList) {
          this.previewList[i].close = this.previewList[i].lastLtp;
        }
        this.isShow = true;
      }
      if (status == "BHAVCOPY") {
        this.settlmentDate = this.bhavCopyDate;
        this.isShow = true;
        for (var j in this.previewList) {
          this.previewList[j].close = 0;
          for (var k in this.req) {
            if (
              this.req[k].expiry == this.previewList[j].expiry &&
              this.req[k].name == this.previewList[j].name
            ) {
              this.previewList[j].close = this.req[k].close;
            }
          }
        }
      }
      this.$refs.errorComponent.updateFormLoader(false);
    },
    showSplitPopup(server_code) {
      if (server_code) {
        this.server_code = server_code;
      }
      this.$refs.split.showModal();
      this.$refs.splitForm.resetForm();
    },
    showClosePostionPopup(server_code) {
      if (server_code) {
        this.server_code = server_code;
      }
      this.$refs.closePosition.showModal();
      this.$refs.closePositionForm.resetForm();
    },
    showDividentPopup(server_code) {
      if (server_code) {
        this.server_code = server_code;
      }
      this.$refs.divident.showModal();
      this.$refs.dividentForm.resetForm();
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    updateList() {
      this.zeroList = [];
      if (this.isZero) {
        for (var item of this.previewList) {
          if (item.close <= 0) {
            this.zeroList.push(item);
          }
        }
      }
    },
    getList(page) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }

      if (this.type) {
        searchObj.type = this.type;
      }

      if (this.role == "ADMIN" || this.role == "SUPER_ADMIN") {
        searchObj.role = "SUPER_MASTER";
      } else if (this.role == "SUPER_MASTER") {
        searchObj.role = "MASTER";
      }

      searchObj.limit = 100;
      this.$api
        .getAPI({
          _action: "/account-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 100);
              } else {
                this.$refs.listPagination.setTotalCount(0, 100);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },
    showDeletePopup(data) {
      var type = "";
      if (data.status == "ACTIVE") {
        type = "Inactive";
      }
      if (data.status == "INACTICE") {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " account",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-account-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => { });
          } else {
            this.getList(0);
          }
        });
    },
    getExpiry(date) {
      return date ? moment(date, "YYYY-MM-DD").format("DDMMM") : "";
    },
    showLimitPopup(data) {
      var type = "";
      if (data.is_limit == 1) {
        type = "Inactive";
      }
      if (data.is_limit == 0) {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " limit Orders",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-limit_order-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => { });
          } else {
            this.getList(0);
          }
        });
    },
    tradeImport(data) {
      // console.log(data);
      this.$api
        .postAPI({
          _action: "/import-trades",
          _body: { trades: data },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => { });
    },
    onSelectFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "application/vnd.ms-excel" &&
        files[0].type.toLowerCase() != "text/csv"
      ) {
        alert("Invalid file formate, please use csv file. " + files[0].type);
        return;
      }
      var reader = new FileReader();
      var vm = this;
      reader.onload = () => {
        var data = [];
        var lines = reader.result.split(/[\r\n]+/g);
        for (var i in lines) {
          if (i > 0) {
            var line = this.$helperService.splitCsv(lines[i]);
            if (line && line[1] && line[1].indexOf("(") != -1) {
              data.push({
                code: line[1].split("(")[1].replace(")", ""),
                scriptname: line[2],
                ex_date: moment(line[3], "DD-MMM-YY").format("YYYY-MM-DD"),

                buysell: line[6].toLowerCase() == "buy" ? "SELL" : "BUY",
                lot: line[7],
                qty: line[8],
                rate: line[9].replace(",", "").replace(/["']/g, ""),
                net_rate: line[9].replace(",", "").replace(/["']/g, ""),
                order_type: "MARKET",
                trade_date:
                  moment(line[10], "DD-MMM-YY").format("YYYY-MM-DD") +
                  " " +
                  line[11],
                type: "BF",
              });
            }
          }
        }

        vm.tradeImport(data);
      };

      reader.readAsText(files[0]);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
