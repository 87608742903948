<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <PageTitle title="MASTERS" />
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 row">
              <div class="col-lg-9 row">
                <div
                  class="col-lg-2 col-sm-3 mt-1"
                  v-if="role == 'SUPER_MASTER' || role == 'MASTER'">
                  <Multiselect
                    mode="single"
                    trackBy="new_name"
                    label="new_name"
                    valueProp="id"
                    class="register-select"
                    placeholder=" SubBroker"
                    v-model="broker_id"
                    :options="broker_list"
                    searchable="true" />
                </div>
                <div
                  class="col-lg-2 col-sm-3 mt-1"
                  v-if="role == 'SUPER_MASTER'">
                  <Multiselect
                    mode="single"
                    trackBy="new_name"
                    label="new_name"
                    valueProp="id"
                    class="register-select"
                    placeholder="Master"
                    v-model="master_id"
                    :options="master_list"
                    searchable="true" />
                </div>
                <div class="col-lg-3 col-sm-2 mt-1">
                  <Field
                    type="text"
                    class="form-control"
                    name="ledger_balance"
                    placeholder="SEARCH"
                    v-model="keyword"
                    v-on:keyup="getList(1)" />
                </div>
                <div class="col-lg-2 col-sm-2 mt-1">
                  <datepicker
                    v-model="fromDate"
                    class="form-control"
                    typeable="true"
                    inputFormat="dd-MM-yyyy"
                    placeholder="From Date"
                    style="width: 100% !important" />
                </div>
                <div class="col-lg-2 col-sm-2 mt-1">
                  <datepicker
                    v-model="toDate"
                    class="form-control"
                    inputFormat="dd-MM-yyyy"
                    placeholder="To Date"
                    typeable="true"
                    style="width: 100% !important" />
                </div>
              </div>
              <div class="col-lg-3 ml-4 text-right">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList(1)"
                  class="btn btn-primary mb-2">
                  Search
                </button>
                &nbsp;
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="ExportUser()"
                  class="btn btn-primary mb-2">
                  Export
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div
            class="col-lg-2"
            style="
              font-weight: bold;
              margin-top: 10px;
              text-align: initial;
              font-size: 13px;
            ">
            Total Customer: {{ count }}
          </div>
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Sr No.</th>
                <th
                  class="head sorting"
                  id="id-name"
                  v-on:click="sorting('name', 'id-name')">
                  Name
                </th>
                <th
                  class="head sorting"
                  id="id-code"
                  v-on:click="sorting('code', 'id-code')">
                  Login ID
                </th>
                <th class="head">Server Code</th>
                <th class="head">Broker</th>
                <th class="head">Master</th>
                <th class="head">Join Time</th>

                <th class="head">Active/De-Active</th>
                <th class="head">Only SquareOff</th>
                <th class="head">Positional Brokerage</th>
                <th class="head" v-if="role == 'SUPER_MASTER'">
                  Admin can Edit
                </th>
                <th class="head">Action</th>
                <!-- <th class="head">Access</th> -->
                <th class="head">Brokerage-Setup</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body">
                  {{ ($refs.listPagination.currentPage - 1) * 20 + index + 1 }}
                </td>
                <td class="body">
                  {{ data.name ? $helperService.getUpperCase(data.name) : "" }}
                </td>
                <td class="body">
                  {{ data.code ? $helperService.getUpperCase(data.code) : "" }}
                </td>
                <td class="body">{{ data.server_code }}</td>
                <td class="body">
                  {{
                    data.broker && data.broker.name
                      ? $helperService.getUpperCase(data.broker.name)
                      : ""
                  }}
                  <span
                    v-if="
                      data.broker_2 &&
                      data.broker_2.name &&
                      data.broker &&
                      data.broker.name
                    ">
                    |
                  </span>
                  {{
                    data.broker_2 && data.broker_2.name
                      ? $helperService.getUpperCase(data.broker_2.name)
                      : ""
                  }}
                  <span
                    v-if="
                      data.broker_2 &&
                      data.broker_2.name &&
                      data.broker_3 &&
                      data.broker_3.name
                    ">
                    |
                  </span>
                  {{
                    data.broker_3 && data.broker_3.name
                      ? $helperService.getUpperCase(data.broker_3.name)
                      : ""
                  }}
                  <span v-if="!data.broker && !data.broker_2 && !data.broker_3">
                    -
                  </span>
                </td>
                <td class="body">
                  {{
                    data.master && data.master.name
                      ? $helperService.getUpperCase(
                          data.master.name + "-" + data.master.code
                        )
                      : "-"
                  }}
                </td>
                <td class="body">
                  {{
                    data.join_date
                      ? $helperService.getCustomDateYear(data.join_date)
                      : ""
                  }}
                </td>

                <td class="body">
                  <div class="form-check form-switch">
                    <input
                      v-on:change="showDeletePopup(data)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      true-value="ACTIVE"
                      style="width: 37px; height: 19px"
                      false-value="INACTIVE"
                      v-model="data.status"
                      :id="'switch_' + index" />
                    <label class="form-check-label" for="switch_1"> </label>
                  </div>
                </td>
                <td class="body">
                  <div class="form-check form-switch">
                    <input
                      v-on:change="showSquareOffPopup(data)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      true-value="1"
                      style="width: 37px; height: 19px"
                      false-value="0"
                      v-model="data.is_only_position_square_off"
                      :id="'switch_' + index" />
                    <label class="form-check-label" for="switch_1"> </label>
                  </div>
                </td>
                <td class="body">
                  <div class="form-check form-switch">
                    <input
                      v-on:change="showDeletePopup2(data)"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      :true-value="1"
                      style="width: 37px; height: 19px"
                      :false-value="0"
                      v-model="data.is_positional_brokerage"
                      :id="'switch_' + index" />
                    <label class="form-check-label" for="switch_1"> </label>
                  </div>
                </td>
                <td class="body" v-if="role == 'SUPER_MASTER'">
                  <span v-if="data.is_edit_permission == 0"
                    ><i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style="font-size: large; cursor: pointer"
                      v-on:click="updatepermission(data)"></i
                  ></span>
                  <span v-if="data.is_edit_permission">
                    <i
                      class="fa fa-unlock-alt"
                      aria-hidden="true"
                      style="font-size: large; cursor: pointer"
                      v-on:click="updatepermission(data)"></i
                  ></span>
                </td>
                <td class="body" style="padding: 10px">
                  <a
                    v-on:click="$router.push('/edit-account/' + data.id)"
                    class="fa fa-pencil-square-o mr-2"
                    style="
                      cursor: pointer;
                      color: rgb(29, 68, 91);
                      font-size: 16px;
                    "
                    title="Edit"></a>
                  &nbsp;
                  <!-- <a
                    v-on:click="showDeletePopup(data)"
                    class="fa fa-trash text-danger"
                    style="cursor: pointer"
                    title="Delete"
                  ></a> -->
                  <!-- <img src="/img/tick.png" v-if="data.status == 'INACTIVE'" />
                  <img
                    src="/img/close.png"
                    v-if="data.status == 'ACTIVE'"
                    v-on:click="showDeletePopup(data, 'inactive')"
                  /> -->
                </td>
                <!-- <td class="body">
                  <i
                    class="fa fa-lock mt-1"
                    aria-hidden="true"
                    style="font-size: 15px; cursor: pointer"
                    v-on:click="$router.push('/access/' + data.id)"></i>
                </td> -->
                <td
                  class="body"
                  style="font-weight: bold; font-size: 15px; cursor: pointer"
                  v-on:click="$router.push('/brokerages/' + data.id)">
                  <span style="font-weight: bold"
                    >MCX -
                    {{
                      data.mcxbrokerage
                        ? data.mcxbrokerage.delivery_brokerage
                        : 0
                    }}</span
                  >
                  |
                  <span style="font-weight: bold"
                    >NSE -
                    {{
                      data.nsebrokerage
                        ? data.nsebrokerage.delivery_brokerage
                        : 0
                    }}</span
                  >
                  <br />
                  <i
                    class="fa fa-eye mt-1"
                    style="font-weight: bold; font-size: 16px; cursor: pointer"
                    aria-hidden="true"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(1)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import swal from "sweetalert2";
import Pagination from "@/components/Pagination";
import Datepicker from "vue3-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import PageTitle from '../../components/PageTitle'


export default {
  components: {
    ErrorComponent,
    Multiselect,
    Datepicker,
    Field,
    Pagination,
    PageTitle
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      role: localStorage.getItem("role"),
      fileHeader:
        "id,name,code,server_code,manager,master,brokerFirst,brokerSec,brokerThird,joinDate,expireDate,mcx_margin,mcx_margin,ledger_balance",
      excel_data: [],
      list: [],
      master_list: [],
      broker_list: [],
      fromDate: "",
      toDate: "",
      count: 0,
      broker_id: "",
      master_id: "",
      type: "",
      keyword: "",
      searchObj: {},
    };
  },
  mounted() {
    this.checkAccess();
    this.getFilter();
    this.getBrokerList();
    this.getMasterList();
  },
  methods: {
    async fetchData() {
      return this.excel_data;
    },
    getFilter() {
      this.searchObj = this.$shareService.getMapValue("customer_filter")
        ? this.$shareService.getMapValue("customer_filter")
        : {};
      console.log("this.searchObj", this.searchObj);
      // this.$helperService.sortingPrevent(
      //   this.searchObj.order_by,
      //   this.searchObj.sort_id
      // );
      if (!this.searchObj.broker_id) {
        this.searchObj.broker_id = "";
      } else {
        this.broker_id = this.searchObj.broker_id;
      }
      if (!this.searchObj.master_id) {
        this.searchObj.master_id = "";
      } else {
        this.master_id = this.searchObj.master_id;
      }
      if (!this.searchObj.keyword) {
        this.searchObj.keyword = "";
      } else {
        this.keyword = this.searchObj.keyword;
      }
      if (this.searchObj.offset || this.searchObj.offset == 0) {
        this.$refs.listPagination.setTotalCount(this.searchObj.count, 20);
        this.$refs.listPagination.setCurrentPage(this.searchObj.offset + 1);
        this.getList(this.searchObj.page);
      } else {
        this.getList(1);
      }
    },
    checkAccess() {
      var access = JSON.parse(localStorage.getItem("access"));
      if (access.is_customer == 0) {
        this.$router.push("/access-denied");
      }
    },
    showSquareOffPopup(data) {
      var type = "";
      if (data.is_only_position_square_off == 1) {
        type = "Inactive";
      }
      if (data.is_only_position_square_off == 0) {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " limit Orders",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-square-of-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(1);
                }
              })
              .catch(() => {});
          } else {
            this.getList(1);
          }
        });
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list = res;
          for (var i in this.master_list) {
            this.master_list[i].new_name =
              this.master_list[i].name + " (" + this.master_list[i].code + ")";

            // if (this.details.user_id == this.master_list[i].id) {
            //   this.user_detail = this.master_list[i];
            // }
          }
        })
        .catch(() => {});
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.broker_list = res;
          for (var i in this.broker_list) {
            this.broker_list[i].new_name =
              this.broker_list[i].name + "(" + this.broker_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(1, obj.order_by, obj.sort_by);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("YYYY-MM-DD");
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("YYYY-MM-DD");
      }

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      searchObj.role = "USER";
      searchObj.offset = page - 1;
      searchObj.limit = 20;
      this.$api
        .getAPI({
          _action: "/account-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.count = res.count;
          searchObj.count = res.count;
          searchObj.page = page;
          this.$shareService.setMapValue("customer_filter", searchObj);
          if (page == 1) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 20);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Date available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },
    showDeletePopup(data) {
      var type = "";
      if (data.status == "ACTIVE") {
        type = "Inactive";
      }
      if (data.status == "INACTICE") {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " account",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-account-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                }
              })
              .catch(() => {});
          } else {
            this.getList(1);
          }
        });
    },
    showDeletePopup2(data) {
      var type = "";
      if (data.is_positional_brokerage == 1) {
        type = "Inactive";
      }
      if (data.is_positional_brokerage == 0) {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " positional brokerage",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-positional-brokerage-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                }
              })
              .catch(() => {});
          } else {
            this.getList(1);
          }
        });
    },

    updatepermission(data) {
      var obj = {};
      obj.id = data.id;
      // var type = "";
      // if (data.status == "ACTIVE") {
      //   type = "Inactive";
      // }
      // if (data.status == "INACTICE") {
      //   type = "Active";
      // }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to update edit account permission",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .putAPI({
                _action: "/update-edit-permission",
                _body: obj,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  data.is_edit_permission = !data.is_edit_permission;
                  return data.is_edit_permission;
                }
              })
              .catch(() => {});
          } else {
            this.getList(1);
          }
        });
    },

    export() {
      this.getList(1);
      this.ExportUser();
    },
    ExportUser() {
      if (!this.fromDate) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please select From Date.",
        });
        return;
      }
      if (!this.toDate) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please select To Date.",
        });
        return;
      }
      this.list = [];
      var searchObj = {};
      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("YYYY-MM-DD");
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("YYYY-MM-DD");
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      searchObj.role = "USER";
      this.$api
        .getAPI({
          _action: "/user-exports",
          _body: searchObj,
        })
        .then((res) => {
          //  "id,name,code,server_code,manager,master,brokerFirst,brokerSec,brokerThird,joinDate,expireDate,mcx_margin,nse_margin,ledger_balance",
          this.excel_data = [];
          if (res.length > 0) {
            for (var j in res) {
              var obj = "";
              obj += res[j].id ? res[j].id : "";
              obj += ",";
              obj += res[j].name ? res[j].name : "";
              obj += ",";
              obj += res[j].code ? res[j].code : "";
              obj += ",";
              obj += res[j].server_code ? res[j].server_code : "";
              obj += ",";
              obj += res[j].manager ? res[j].manager.name : "";
              obj += res[j].manager ? "(" + res[j].manager.code + ")" : "";
              obj += ",";
              obj += res[j].master ? res[j].master.name : "";
              obj += res[j].master ? "(" + res[j].master.code + ")" : "";
              obj += ",";
              obj += res[j].broker ? res[j].broker.name : "";
              obj += res[j].broker ? "(" + res[j].broker.code + ")" : "";
              obj += ",";
              obj += res[j].broker_2 ? res[j].broker_2.name : "";
              obj += res[j].broker_2 ? "(" + res[j].broker_2.code + ")" : "";
              obj += ",";
              obj += res[j].broker_3 ? res[j].broker_3.name : "";
              obj += res[j].broker_3 ? "(" + res[j].broker_3.code + ")" : "";
              obj += ",";
              obj += res[j].join_date
                ? this.$helperService.getDate(res[j].join_date)
                : "";
              obj += ",";
              obj += res[j].expire_date
                ? this.$helperService.getDate(res[j].expire_date)
                : "";
              obj += ",";
              obj += res[j].mcx_margin ? res[j].mcx_margin : 0;
              obj += ",";
              obj += res[j].nse_margin ? res[j].nse_margin : 0;
              obj += ",";
              obj += res[j].ledger_balance ? res[j].ledger_balance : 0;
              this.excel_data.push(obj);
            }
          }
          setTimeout(() => {
            this.download();
          }, 1000);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              { list: this.list },
              "No Data available"
            );
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    download() {
      let filename = "Client-list.csv";
      let text = this.fileHeader;
      console.log("excel_data", this.excel_data);
      for (var i in this.excel_data) {
        text += "\n";
        text += this.excel_data[i];
      }
      let blob = new Blob([text], { type: "text/csv" });
      let link = document.createElement("a");
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }, 100);
    },
  },
};

</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
