<template>
  <div>
    <div class="container form-signin3">
      <div class="card-bg col-md-12 dealer-form form-signin3 mt-0">
        <div class="container">
          <div class="border-page">
            <Form ref="account" @submit="save()">
              <div class="col-12 form row mb-2">
                <div class="col-4">
                  <label class="col-form-label" style="display: flex">
                    Valan
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="role"
                    class="register-select"
                    v-model="details.role"
                    v-on:change="resetForm()"
                    as="select"
                    rules="required:role"
                    :validateOnInput="true"
                  >
                    <option value="" disabled>Valan</option>
                    <option
                      v-for="(data, index) in list"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.type }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="role"
                    class="text-danger"
                    style="display: flex"
                  />
                </div>
                <div class="col-4">
                  <label class="col-form-label" style="display: flex">
                    Segment
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="role"
                    class="register-select"
                    v-model="details.role"
                    v-on:change="resetForm()"
                    as="select"
                    rules="required:role"
                    :validateOnInput="true"
                  >
                    <option value="" disabled>Segment</option>
                    <option
                      v-for="(data, index) in list"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.type }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="role"
                    class="text-danger"
                    style="display: flex"
                  />
                </div>
                <div class="col-4">
                  <label class="col-form-label" style="display: flex">
                    Sub Broker
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="role"
                    class="register-select"
                    v-model="details.role"
                    v-on:change="resetForm()"
                    as="select"
                    rules="required:role"
                    :validateOnInput="true"
                  >
                    <option value="" disabled>Sub Broker</option>
                    <option
                      v-for="(data, index) in list"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.type }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="role"
                    class="text-danger"
                    style="display: flex"
                  />
                </div>
                <div class="col-4">
                  <label class="col-form-label" style="display: flex">
                    Filter By
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="role"
                    class="register-select"
                    v-model="details.role"
                    v-on:change="resetForm()"
                    as="select"
                    rules="required:role"
                    :validateOnInput="true"
                  >
                    <option value="" disabled>Filter By</option>
                    <option
                      v-for="(data, index) in list"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.type }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="role"
                    class="text-danger"
                    style="display: flex"
                  />
                </div>
                <div class="col-4">
                  <label class="col-form-label" style="display: flex">
                    Order By
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="role"
                    class="register-select"
                    v-model="details.role"
                    v-on:change="resetForm()"
                    as="select"
                    rules="required:role"
                    :validateOnInput="true"
                  >
                    <option value="" disabled>Order By</option>
                    <option
                      v-for="(data, index) in list"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.type }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="role"
                    class="text-danger"
                    style="display: flex"
                  />
                </div>

                <div class="col-4 mt-2">
                   <label class="col-form-label" style="display: flex">
                    Date Filter
                    <span class="text-danger">*</span>
                  </label>
                  <div style="display: flex">
                    <input type="checkbox" class="ml-1" />
                    <label class="col-form-label ml-2"> All Date </label>
                    <input type="checkbox" class="ml-2" />
                    <label class="col-form-label ml-2"> Data Wise </label>
                  </div>
                </div>

                <div class="col-4 mt-2">
                 

                  <div style="display: flex">
                    <input type="checkbox" class="ml-1" />
                    <label class="col-form-label ml-2"> T Format Report </label>
                    <input type="checkbox" class="ml-2" />
                    <label class="col-form-label ml-2"> Without Group </label>
                    <input type="checkbox" class="ml-2" />
                    <label class="col-form-label ml-2">
                      Exclude Subbroker Client
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-md-12" style="display: flex">
                <button
                  class="save-btn px-4 ml-3"
                  type="submit"
                  id="save-button"
                >
                  View Report
                </button>
                <button class="save-btn ml-3" type="submit" id="save-button">
                  Excel Report
                </button>
                <button
                  class="save-btn px-4 ml-3"
                  type="submit"
                  id="save-button"
                >
                  Send Email
                </button>
              </div>
              <div class="col-md-12 text-right"></div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetails()" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },
  watch: {
    "$route.fullPath"() {
      this.$refs.account.resetForm();
      this.id = "";
    },
  },
  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
      role: localStorage.getItem("role"),
      password: "",
      confirm_password: "",
      list: [],
      details: {
        id: 1,
        name: "",
        code: "",
        join_date: "",
        expire_date: "",
        logo: "",
        company_name: "",
        sharing: "",
        brokerage: "",
        no_of_master: "",
        no_of_broker: "",
        no_of_user: "",
        note: "",
        is_allow_nse: 0,
        is_allow_mcx: 0,
        is_allow_mobile_application: 0,
        is_order_between_high_low: 0,
        opening_balance: "",
        sub_broker: "",
        is_auto_square_off: 0,
        is_only_position_square_off: 0,
        margin_amount: "",
        ledger_balance: "",
        mtm_percentage: "",
        close_percentage: "",
        mtm_profit: "",
        mtm_loss: "",
      },
      bank: {},
    };
  },
  mounted() {
    if (this.id) {
      this.getDetails();
    }
    this.getTypeList();
  },
  methods: {
    getTypeList() {
      if (this.role == "ADMIN") {
        this.list = [
          {
            id: "SUPER_MASTER",
            type: "Super Master",
          },
        ];
      } else if (this.role == "SUPER_MASTER") {
        this.list = [
          {
            id: "MASTER",
            type: "Master",
          },
          {
            id: "BROKER",
            type: "Broker",
          },
          {
            id: "USER",
            type: "User",
          },
        ];
      } else if (this.role == "MASTER") {
        this.list = [
          {
            id: "BROKER",
            type: "Broker",
          },
          {
            id: "USER",
            type: "User",
          },
        ];
      } else if (this.role == "BROKER") {
        this.list = [
          {
            id: "USER",
            type: "User",
          },
        ];
      }
    },
    getDetails() {
      if (this.id) {
        this.$refs.errorComponent.updateFormLoader(true);
        this.$api
          .getAPI({
            _action: "/account-detail/" + this.id,
          })
          .then((res) => {
            res.expire_date = moment(res.expire_date).toDate();
            res.join_date = moment(res.join_date).toDate();
            this.details = res;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      }
    },
    resetForm() {
      this.$refs.account.resetForm();
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/account",
            _body: this.details,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              this.$notify({
                 title: "Success",
                        type: "success",
                text: res.message,
              });
              if (this.details.role == "USER") {
                this.$router.push("/users");
              } else if (this.details.role == "BROKER") {
                this.$router.push("/broker");
              } else if (this.details.role == "MASTER") {
                this.$router.push("/masters");
              } else if (this.details.role == "SUPER_MASTER") {
                this.$router.push("/super-masters");
              }
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/account",
            _body: this.details,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              this.$notify({
                 title: "Success",
                        type: "success",
                text: res.message,
              });
              if (this.details.role == "USER") {
                this.$router.push("/users");
              } else if (this.details.role == "BROKER") {
                this.$router.push("/broker");
              } else if (this.details.role == "MASTER") {
                this.$router.push("/masters");
              } else if (this.details.role == "SUPER_MASTER") {
                this.$router.push("/super-masters");
              }
            }
          })
          .catch(() => {});
      }
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      obj.old_password = this.old_password;
      obj.new_password = this.new_password;
      obj.id = this.id;
      this.$api
        .putAPI({
          _action: "/reset/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
             title: "Success",
                        type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>