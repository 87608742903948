<template>
  <div>
    <div class="container form-signin3">
      <div class="card-bg col-md-12 dealer-form form-signin100 mt-0">
        <h2
          class="text-start"
          style="color: white; font-weight: 600:">
          ACCESS DENIED
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  name: "Profile",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
