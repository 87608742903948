<template>
  <div v-show="isShowModal">
    <div class="sds-modal h-100 justify-content-center">
      <div class="sds-modal-body-new mx-auto">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="closeModal()">
            <span aria-hidden="true" style="color: white;">&times;</span>
          </button>
        </div>
        <div class="container form-signin3">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 50px; padding-right: 50px"
            ></div>
          </div>

          <div
            class="row"
            style="padding-left: 50px; padding-right: 50px; margin-top: 5px"
          >
            <div class="col-lg-12 row ml-1">
              <p
                style="
                  color: black;
                  text-align: justify;
                  font-weight: bold;
                  font-size: 12px;
                  margin: 0px !important;
                  margin-top: 7px !important;
                "
              >
                POSITION BOOK
              </p>
              <div class="col-lg-2">
                <Field
                  type="text"
                  class="form-control"
                  name="ledger_balance"
                  placeholder="SEARCH"
                  v-model="keyword"
                  v-on:keyup="getList()"
                />
              </div>
              <div class="col-2" style="display: contents">
                <button
                  id="btn-dd"
                  type="button"
                  style="background-color: orange !important"
                  v-on:click="rollOverPosition()"
                  class="btn btn-primary mb-2"
                >
                  Rollover
                </button>
              </div>
              <div class="col-2">
                <button
                  id="btn-dd"
                  type="button"
                  v-on:click="closePosition()"
                  class="btn btn-primary mb-2"
                >
                  Close All Position
                </button>
              </div>
            </div>
            <div class="col-md-12 bg-light border-page">
              <!-- <div
                class="col-lg-12"
                style="justify-content: flex-end; display: flex"
              >
                <div class="col-2">
                  <Field
                    type="text"
                    class="form-control"
                    name="ledger_balance"
                    placeholder="SEARCH"
                    v-model="keyword"
                    v-on:keyup="getList()"
                  />
                </div>
              </div> -->
              <div class="sticky-table-header">
                <table
                  id="table"
                  style="margin-top: 16px"
                  data-toggle="table"
                  data-search="true"
                  data-filter-control="true"
                  data-toolbar="#toolbar"
                  class="table table-sm text-center text-white table-bordered product-list table-hover"
                >
                  <thead>
                    <tr>
                      <th class="head ml-2">
                        <div class="switchToggleRead">
                          <input
                            type="checkbox"
                            v-model="isChecked"
                            :true-value="true"
                            :false-value="false"
                            v-on:change="checkall(isChecked)"
                          />
                        </div>
                      </th>
                      <th
                        class="head sorting"
                        id="id-instrument_token"
                        v-on:click="
                          sorting('trades.exchange', 'id-instrument_token')
                        "
                      >
                        Segment
                      </th>
                      <th
                        class="head sorting"
                        id="id-name"
                        v-on:click="sorting('users.id', 'id-name')"
                      >
                        Client
                      </th>
                      <th
                        class="head sorting"
                        id="id-trading_symbol"
                        v-on:click="
                          sorting('trades.trading_symbol', 'id-trading_symbol')
                        "
                      >
                        Symbol
                      </th>
                      <th
                        class="head sorting"
                        id="id-quantity"
                        v-on:click="
                          sorting('total_buy_quantity', 'id-quantity')
                        "
                      >
                        Total Buy Qty
                      </th>

                      <th
                        class="head sorting"
                        id="id-avg_buy_price"
                        v-on:click="
                          sorting('avg_buy_price', 'id-avg_buy_price')
                        "
                      >
                        Buy Avg. Price
                      </th>
                      <th
                        class="head sorting"
                        id="id-total_sell_quantity"
                        v-on:click="
                          sorting(
                            'total_sell_quantity',
                            'id-total_sell_quantity'
                          )
                        "
                      >
                        Total Sell Qty
                      </th>

                      <th
                        class="head sorting"
                        id="id-avg_sell_price"
                        v-on:click="
                          sorting('avg_sell_price', 'id-avg_sell_price')
                        "
                      >
                        Sell Avg. Price
                      </th>
                      <th class="head">Net Lot</th>
                      <th
                        class="head"
                        id="id-net_qty"
                        v-on:click="sorting('net_qty', 'id-net_qty')"
                      >
                        Net Qty
                      </th>
                      <th
                        class="head"
                        id="id-name"
                        v-on:click="sorting('id', 'id-name')"
                      >
                        B.E. Avg.
                      </th>
                      <th
                        class="head"
                        id="id-name"
                        v-on:click="sorting('id', 'id-name')"
                      >
                        LTP
                      </th>
                      <th
                        class="head"
                        id="id-name"
                        v-on:click="sorting('id', 'id-name')"
                      >
                        MTM
                      </th>
                    </tr>
                  </thead>
                  <tbody v-show="!loader">
                    <tr v-for="(data, index) in list" :key="index">
                      <td style="font-size: 11px" class="pl-1 pt-1 pr-1 p-0">
                        <!-- v-if="data.net_qty != 0" -->
                        <div class="switchToggleRead" v-if="data.net_qty != 0">
                          <input
                            :id="'index_' + data.id"
                            type="checkbox"
                            v-model="data.isChecked"
                            v-on:change="checkHandlling(data.isChecked)"
                            :true-value="true"
                            :false-value="false"
                          />
                        </div>
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                     
{{
                      data.exchange
                        ? data.exchange == "NFO"
                          ? "NSE"
                          : "MCX"
                        : ""
                    }}

                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{
                          data.user
                            ? $helperService.getUpperCase(
                                data.user?.name + "-" + data.user?.code
                              )
                            : ""
                        }}
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{ data.trading_symbol ? data.trading_symbol : "" }}
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{
                          Number(data.total_buy_quantity)
                            ? $helperService.getFormattedPrice(
                                parseInt(Number(data.total_buy_quantity))
                              )
                            : 0
                        }}
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{
                          Number(data.avg_buy_price)
                            ? $helperService.getFormattedPrice(
                                Number(data.avg_buy_price)
                              )
                            : 0
                        }}
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{
                          Number(data.total_sell_quantity)
                            ? $helperService.getFormattedPrice(
                                parseInt(Number(data.total_sell_quantity))
                              )
                            : 0
                        }}
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{
                          Number(data.avg_sell_price)
                            ? $helperService.getFormattedPrice(
                                Number(data.avg_sell_price)
                              )
                            : 0
                        }}
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{ data.net_lot ? data.net_lot?.toFixed(2) : 0 }}
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{ data.net_qty }}
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{ data.bea ? data.bea?.toFixed(2) : 0 }}
                      </td>

                      <td
                        v-on:click="setBuySellDetail(data)"
                        style="
                          padding: 0px !important;
                          font-size: 13px !important;
                        "
                        v-if="data.ltp"
                      >
                        <div
                          style="padding: 0.3rem"
                          :style="'background-color:' + data.ltpc"
                        >
                          {{ data.ltp?.toFixed(2) }}
                        </div>
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        v-if="!data.ltp"
                        :class="
                          Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        <!-- {{ data.m2m?.toFixed(2) }} -->
                      </td>
                      <td
                        v-on:click="setBuySellDetail(data)"
                        style="font-weight: bold; font-size: 13px !important"
                        :class="
                          Number(data.m2m?.toFixed(2)) > 0
                            ? 'body-buy'
                            : 'body-sell'
                        "
                      >
                        {{ data.m2m?.toFixed(2) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ErrorComponent @retry="getList()" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sds-modal-background"></div>

    <!-- ===================================== -->
    <div
      id="dashfoot"
      v-if="is_show_buy_sell_section"
      class="buy-sell-section ng-scope"
      style="z-index: 9999999 !important"
      :style="
        buySellObj.order_action == 'BUY'
          ? 'background-color:rgb(31, 88, 204)'
          : 'background-color:rgb(161, 51, 41)'
      "
    >
      <Form ref="buySell" @submit="submit()">
        <div class="row" style="margin-left: 0px; margin-right: 0px">
          <div class="col-12 col-lg-3 col-xl-3">
            <div class="form-group row">
              <div class="col-sm-12">
                <br />
                <Field
                  type="text"
                  class="form-control"
                  name="buySellObjName"
                  placeholder="Script"
                  disabled
                  v-model="buySellObj.trading_symbol"
                />
              </div>
            </div>
          </div>

          <div
            v-if="buySellObj.order_action == 'SELL'"
            :style="'background-color:' + buySellObj.bc + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red"
          >
            <label class="col-form-label" style="color: white !important"
              >Bid Rate </label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.b?.toFixed(2)
            }}</span>
          </div>
          <div
            v-if="buySellObj.order_action == 'BUY'"
            :style="'background-color:' + buySellObj.ac + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red"
          >
            <label class="col-form-label" style="color: white !important"
              >Ask Rate </label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.a?.toFixed(2)
            }}</span>
          </div>
          <div
            :style="'background-color:' + buySellObj.ltpc + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red"
          >
            <label class="col-form-label" style="color: white !important"
              >LTP</label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.ltp?.toFixed(2)
            }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price"
          >
            <label class="col-form-label">Change %</label><br /><span>{{
              buySellObj.ch?.toFixed(2)
            }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price"
          >
            <label class="col-form-label">Net Chg</label><br />
            <span class="ng-binding">{{ buySellObj.n?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price"
          >
            <label class="col-form-label">High</label><br />
            <span class="ng-binding">{{ buySellObj.h?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price"
          >
            <label class="col-form-label">Low</label><br />
            <span class="ng-binding">{{ buySellObj.l?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price"
          >
            <label class="col-form-label">Open</label><br />
            <span class="ng-binding">{{ buySellObj.o?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price"
          >
            <label class="col-form-label">Close</label><br />
            <span class="ng-binding">{{ buySellObj.c?.toFixed(2) }}</span>
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-sm-2 mt-2">
            <div class="custom-control custom-radio d-block float-left">
              <Field
                v-model="buySellObj.order_action"
                type="radio"
                value="BUY"
                id="gross_partnership"
                name="net_gross_partner"
                class="custom-control-input ng-pristine ng-untouched ng-valid"
                checked="checked"
                disabled
                style="height: unset !important"
              />
              <label for="gross_partnership" class="custom-control-label"
                >Buy</label
              >
            </div>
            <div class="custom-control custom-radio d-block float-right">
              <Field
                v-model="buySellObj.order_action"
                type="radio"
                value="SELL"
                id="net_partnership"
                disabled
                name="net_gross_partner"
                class="custom-control-input ng-pristine ng-untouched ng-valid"
                style="height: unset !important"
              />
              <label for="net_partnership" class="custom-control-label"
                >Sell</label
              >
            </div>
            <Field
              name="order_type"
              class="register-select mt-2"
              as="select"
              v-model="buySellObj.order_type"
              rules="required: order type"
              :validateOnInput="true"
            >
              <option value="" disabled>Order Type</option>
              <option value="MARKET">Market</option>
              <option value="LIMIT">Limit</option>
              <!-- <option value="INTRADAY">Intraday</option>
              <option value="STOPLOSS">Stoploss</option> -->
            </Field>
            <ErrorMessage name="order_type" class="text-white" />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type != 'INTRADAY'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Lot</label
            ><br />
            <Field
              type="number"
              class="form-control"
              name="Lot"
              min="1"
              autocomplete="off"
              v-model="buySellObj.lot_size"
              v-on:keyup="updateQuantity(buySellObj.lot_size)"
              placeholder="Lot"
              rules="required:lot"
              :validateOnInput="true"
              v-on:keypress="$helperService.allowDecimalValue($event)"
            />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type != 'INTRADAY'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Qty</label
            ><br />

            <Field
              type="number"
              class="form-control"
              min="1"
              autocomplete="off"
              name="Qty"
              :style="buySellObj.exchange == 'MCX' ? 'cursor: no-drop;' : ''"
              v-model="buySellObj.quantity"
              v-on:keyup="updateLot(buySellObj.lot_size)"
              placeholder="Qty"
              rules="required:quantity"
              :validateOnInput="true"
              :disabled="buySellObj.exchange == 'MCX'"
              v-on:keypress="$helperService.allowDecimalValue($event)"
            />
            <ErrorMessage name="Qty" class="text-white" />
            <p v-if="isQuantity" style="font-size: 11px; color: white">
              {{ message }}
            </p>
          </div>
          <div
            class="col-sm-2 mt-1"
            v-if="
              buySellObj.order_type == 'MARKET' &&
              buySellObj.order_action == 'BUY'
            "
          >
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              name="Price"
              placeholder="Price"
              autocomplete="off"
              :disabled="buySellObj.order_type == 'MARKET'"
              v-model="buySellObj.a"
              rules="required:price"
              :validateOnInput="true"
              v-on:keypress="$helperService.allowDecimalValue($event)"
            />
            <ErrorMessage name="Price" class="text-white" />
          </div>
          <div
            class="col-sm-2 mt-1"
            v-if="
              buySellObj.order_type == 'MARKET' &&
              buySellObj.order_action == 'SELL'
            "
          >
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              name="marketPrice"
              autocomplete="off"
              placeholder="Price"
              :disabled="buySellObj.order_type == 'MARKET'"
              v-model="buySellObj.b"
              rules="required:price"
              :validateOnInput="true"
              v-on:keypress="$helperService.allowDecimalValue($event)"
            />
            <ErrorMessage name="Price" class="text-white" />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type == 'LIMIT'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              autocomplete="off"
              class="form-control"
              name="limitPrice"
              placeholder="Price"
              v-model="buySellObj.price"
              rules="required:price"
              :validateOnInput="true"
              v-on:keyup="checkMultiple(buySellObj.price, buySellObj.tick_size)"
              v-on:keypress="$helperService.allowDecimalValue($event)"
            />
            <ErrorMessage name="Price" class="text-white" />
            <p v-if="isTickSize" style="font-size: 13px; color: white">
              Price Should be in multiple of {{ buySellObj.tick_size }}
            </p>
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type == 'STOPLOSS'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              autocomplete="off"
              name="stoplossPrice"
              placeholder="Price"
              v-model="buySellObj.price"
              rules="required:price"
              :validateOnInput="true"
              v-on:keyup="checkMultiple(buySellObj.price, buySellObj.tick_size)"
              v-on:keypress="$helperService.allowDecimalValue($event)"
            />
            <ErrorMessage name="Price" class="text-white" />
            <p v-if="isTickSize" style="font-size: 13px; color: white">
              Price Should be in multiple of {{ buySellObj.tick_size }}
            </p>
          </div>
          <div class="col-sm-2 mt-1">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Client</label
            ><br />
            <Field
              type="text"
              name="user"
              disabled
              v-model="buySellObj.user_id"
              rules="required:user"
              :validateOnInput="true"
            >
              <Multiselect
                mode="single"
                trackBy="new_name"
                label="new_name"
                valueProp="id"
                class="register-select"
                placeholder="Client"
                disabled
                v-model="buySellObj.user_id"
                :options="client_list"
                openDirection="top"
                searchable="true"
              />
              <ErrorMessage name="user" class="text-white" />
            </Field>
          </div>
          <div class="col-sm-2 mt-4">
            <button id="submitForm" class="btn btn-success m-1">
              <i class="fa fa-trade"></i>
              <span> Submit </span>
            </button>
            <button
              type="button"
              v-on:click="closeBuySellPopup()"
              class="btn btn-danger m-1"
            >
              <i class="fa fa-trade"></i>
              <span> Cancel </span>
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import webstomp from "webstomp-client";
import swal from "sweetalert2";
// import Datepicker from "vue3-datepicker";
export default {
  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.closeBuySellPopup();
      }
    });
  },
  name: "TextModal",
  components: {
    ErrorComponent,
    Field,
    Form,
    ErrorMessage,
    Multiselect,
    // Datepicker,
  },
  data() {
    return {
      isShowModal: false,
      is_show_buy_sell_section: false,
      isChecked: false,
      loader: true,
      isTickSize: false,
      lot_size: 0,
      list: [],
      client_list: [],
      id: "",
      fromDate: "",
      toDate: "",
      type: "",
    };
  },
  mounted() {
    this.connect();
    this.getClientList();
    var $this = this;
    setInterval(() => {
      $this.checkConnection();
    }, 5000);
  },
  methods: {
    checkMultiple(price, tick_size) {
      if (price && tick_size) {
        var n = price;
        n = (n - Math.floor(n)).toFixed(2);
        var tick = tick_size;
        var is_check = (n / tick).toFixed(2) % 1 == 0;
        if (is_check) {
          this.isTickSize = false;
        } else {
          this.isTickSize = true;
        }
        console.log("is_check", this.isTickSize);
      }
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + " (" + this.client_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    submit() {
      if (this.isTickSize) {
        return;
      }
      if (this.isQuantity) {
        return;
      }

      swal
        .fire({
          title: "Are you sure?",
          text: "You sure want to place order.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Place Order",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (
              Number(this.buySellObj.price) < Number(this.buySellObj.ll) &&
              this.buySellObj.order_type != "MARKET" &&
              this.buySellObj.order_type != "INTRADAY"
            ) {
              swal.fire({
                title: "Error",
                text:
                  "price should be greater then lower limit " +
                  this.buySellObj.ll +
                  ".",
                icon: "error",
                showCancelButton: true,
                showConfirmButton: false,
              });
              this.reject_reason =
                "price should be greater then lower limit " +
                this.buySellObj.ll +
                ".";
            }
            if (Number(this.buySellObj.price) > Number(this.buySellObj.ul)) {
              swal.fire({
                title: "Error",
                text:
                  "price should be less then upper limit " +
                  this.buySellObj.ul +
                  ".",
                icon: "error",
                showCancelButton: true,
                showConfirmButton: false,
              });
              this.reject_reason =
                "price should be less then upper limit " +
                this.buySellObj.ul +
                ".";
            }

            if (
              this.buySellObj.order_action == "BUY" &&
              this.buySellObj.order_type == "STOPLOSS"
            ) {
              if (
                this.buySellObj.price <= this.buySellObj.ltp &&
                this.buySellObj.price <= this.buySellObj.a
              ) {
                swal.fire({
                  title: "Error",
                  text: "Stoploss price should be greater then ltp price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Stoploss price should be greater then ltp price.";
              }
            }

            if (
              this.buySellObj.order_action == "SELL" &&
              this.buySellObj.order_type == "STOPLOSS"
            ) {
              if (
                this.buySellObj.price >= this.buySellObj.ltp &&
                this.buySellObj.price >= this.buySellObj.b
              ) {
                swal.fire({
                  title: "Error",
                  text: "Stoploss price should be less then ltp price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Stoploss price should be less then ltp price.";
              }
            }

            if (this.buySellObj.order_type == "LIMIT") {
              if (
                this.buySellObj.price >= this.buySellObj.b &&
                this.buySellObj.price <= this.buySellObj.a
              ) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should not be in between bid price and ask price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Limit price should not be in between bid price and ask price.";
              }
            }
            if (
              this.buySellObj.order_type == "LIMIT" &&
              this.buySellObj.order_action == "BUY"
            ) {
              if (this.buySellObj.price > this.buySellObj.ltp) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should be Less then LTP.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason = "Limit price should be Less then LTP.";
              }
            }
            if (
              this.buySellObj.order_type == "LIMIT" &&
              this.buySellObj.order_action == "SELL"
            ) {
              if (this.buySellObj.price < this.buySellObj.ltp) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should be greater then LTP.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason = "Limit price should be greater then LTP.";
              }
            }
            this.buySellObj.device = "WEB";
            if (
              this.buySellObj.order_action == "BUY" &&
              this.buySellObj.order_type == "MARKET"
            ) {
              this.buySellObj.price = this.buySellObj.a;
            } else if (
              this.buySellObj.order_action == "SELL" &&
              this.buySellObj.order_type == "MARKET"
            ) {
              this.buySellObj.price = this.buySellObj.b;
            }

            if (this.reject_reason) {
              this.buySellObj.reject_reason = this.reject_reason;
            }
            if (this.buySellObj.price) {
              this.buySellObj.price = Number(this.buySellObj.price);
            }
            this.$api
              .postAPI({
                _action: "/add-trade",
                _body: this.buySellObj,
                _buttonId: "submitForm",
              })
              .then(() => {
                // this.is_show_buy_sell_section = false;

                if (!this.reject_reason) {
                  if (this.buySellObj.order_type == "MARKET") {
                    for (var i in this.client_list) {
                      if (this.client_list[i].id == this.buySellObj.user_id) {
                        var code = this.client_list[i].code;
                        var name = this.client_list[i].name;
                      }
                    }
                    var text =
                      "Trade has been successfully executed. " +
                      this.buySellObj.name_new +
                      "   " +
                      this.$helperService.getCustomDateYear(
                        this.buySellObj.expiry
                      ) +
                      "   " +
                      this.buySellObj.order_action +
                      " " +
                      this.buySellObj.quantity +
                      "@" +
                      Number(this.buySellObj.price).toFixed(2) +
                      "    For " +
                      this.$helperService.getUpperCase(name) +
                      "(" +
                      code +
                      ")";
                    if (this.buySellObj.order_action == "SELL") {
                      this.$notify({
                        title: "SELL",
                        type: "error",
                        text: text,
                      });
                    }
                    if (this.buySellObj.order_action == "BUY") {
                      this.$notify({
                        title: "BUY",
                        type: "success",
                        text: text,
                      });
                    }
                  } else {
                    for (var j in this.client_list) {
                      if (this.client_list[j].id == this.buySellObj.user_id) {
                        var code_new = this.client_list[j].code;
                        var name_new = this.client_list[j].name;

                        this.client_list[j].name +
                          this.$helperService.getCustomDate(
                            this.client_list[j].expiry
                          );
                      }
                    }
                    if (this.buySellObj.order_type != "INTRADAY") {
                      var text_new =
                        "Order Placed Successfully " +
                        this.buySellObj.name_new +
                        "   " +
                        this.$helperService.getCustomDateYear(
                          this.buySellObj.expiry
                        ) +
                        "   " +
                        this.buySellObj.order_action +
                        " " +
                        this.buySellObj.quantity +
                        "@" +
                        Number(this.buySellObj.price).toFixed(2) +
                        "    For " +
                        this.$helperService.getUpperCase(name_new) +
                        "(" +
                        code_new +
                        ")";
                      if (this.buySellObj.order_action == "SELL") {
                        this.$notify({
                          title: "SELL",
                          type: "error",
                          text: text_new,
                        });
                      }
                      if (this.buySellObj.order_action == "BUY") {
                        this.$notify({
                          title: "BUY",
                          type: "success",
                          text: text_new,
                        });
                      }
                    } else {
                      var text_new_2 =
                        "Intraday Close Order Placed Successfully " +
                        this.buySellObj.name_new +
                        "   " +
                        this.$helperService.getCustomDateYear(
                          this.buySellObj.expiry
                        ) +
                        "    For " +
                        this.$helperService.getUpperCase(name_new) +
                        "(" +
                        code_new +
                        ")";
                      this.$notify({
                        title: "",
                        type: "success",
                        text: text_new_2,
                      });
                    }
                  }
                }
                this.$refs.buySell.resetForm();
                this.buySellObj.price = "";
                this.buySellObj.quantity = "";
                this.buySellObj.lot = "";
                this.buySellObj.user_id = "";
                this.reject_reason = "";
                this.buySellObj.reject_reason = "";
                this.buySellObj.order_action = "BUY";
                this.buySellObj.order_type = "MARKET";
                this.is_show_buy_sell_section = false;
                this.getList();
              })
              .catch(() => {
                this.$refs.buySell.resetForm();
                this.buySellObj.price = "";
                this.buySellObj.quantity = "";
                this.buySellObj.lot = "";
                this.buySellObj.user_id = "";
                this.reject_reason = "";
                this.buySellObj.reject_reason = "";
                this.buySellObj.order_action = "BUY";
                this.buySellObj.order_type = "MARKET";
                this.is_show_buy_sell_section = false;
                this.getList();
              });
          } else {
            this.$refs.buySell.resetForm();
            this.buySellObj.price = "";
            this.buySellObj.quantity = "";
            this.buySellObj.lot = "";
            this.buySellObj.user_id = "";
            this.reject_reason = "";
            this.buySellObj.reject_reason = "";
            this.buySellObj.order_action = "BUY";
            this.buySellObj.order_type = "MARKET";
            this.is_show_buy_sell_section = false;
            this.getList();
          }
        });
    },
    setBuySellDetail(data) {
      if (data.net_qty == 0) {
        return;
      }

      if (!data.script || !data.script.lot_size) {
        return;
      }

      this.message = "";
      this.isQuantity = false;
      this.is_show_buy_sell_section = true;
      this.buySellObj = { ...data };
      this.buySellObj.order_type = "MARKET";
      this.buySellObj.lot_size = data.net_lot;
      this.buySellObj.user_id = data.user_id;
      this.lot_size = data.script.lot_size;

      this.buySellObj.lot_size = Math.abs(data.net_lot);
      this.buySellObj.quantity = Math.abs(this.buySellObj.net_qty);
      if (this.buySellObj.net_qty > 0) {
        this.buySellObj.order_action = "SELL";
      }
      if (this.buySellObj.net_qty < 0) {
        this.buySellObj.order_action = "BUY";
      }
      this.buySellObj.name_new =
        data.name + this.$helperService.getCustomDate(data.expiry);
      this.buySellObj.trading_symbol =
        data.name + " " + this.$helperService.getCustomDate(data.expiry);
    },
    closeBuySellPopup() {
      this.$refs.buySell.resetForm();
      this.buySellObj.price = "";
      this.buySellObj.quantity = "";
      this.buySellObj.lot = "";
      this.buySellObj.user_id = "";
      this.reject_reason = "";
      this.buySellObj.reject_reason = "";
      this.buySellObj.order_action = "BUY";
      this.buySellObj.order_type = "MARKET";
      this.is_show_buy_sell_section = false;
    },
    closePosition() {
      this.$refs.errorComponent.updateFormLoader(true);
      var obj = {};
      var arr = [];
      obj.device = "WEB";
      for (var k in this.list) {
        if (this.list[k].isChecked == true) {
          arr.push(this.list[k]);
        }
      }
      obj.close_position_array = arr;
      if (arr.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select Postions First",
        });
        this.$refs.errorComponent.updateFormLoader(false);
        return;
      }
      this.$api
        .postAPI({
          _action: "/close-position",
          _body: obj,
        })
        .then((res) => {
          this.$refs.errorComponent.updateFormLoader(false);
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.getList();
        })
        .catch(() => {
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    rollOverPosition() {
      this.$refs.errorComponent.updateFormLoader(true);
      var obj = {};
      var arr = [];
      obj.device = "WEB";
      for (var k in this.list) {
        if (this.list[k].isChecked == true) {
          arr.push(this.list[k]);
        }
      }
      obj.close_position_array = arr;
      if (arr.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select Postions First",
        });
        this.$refs.errorComponent.updateFormLoader(false);
        return;
      }
      this.$api
        .postAPI({
          _action: "/roll-over",
          _body: obj,
        })
        .then((res) => {
          this.$refs.errorComponent.updateFormLoader(false);
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.getList();
        })
        .catch(() => {
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          if (this.list[k].net_qty != 0) {
            this.list[k].isChecked = true;
          }
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = 0;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
      }

      if (count == 0) {
        this.isChecked = true;
      }
    },
    checkConnection() {
      if (!this.client || !this.client.connected) {
        // console.log("try to reconnect connected");
        this.connect();
      } else {
        // console.log("already connected");
      }
    },
    connect() {
      var $this = this;
      this.client = webstomp.client(this.$api.url, { debug: false });
      
      this.client.connect(this.$api.username, this.$api.password, () => {
        this.client.subscribe("/exchange/market-data", (message) => {
          $this.showData(JSON.parse(message.body));
        });
      });
    },
    showData(data) {
      for (var i in data) {
        for (var j in this.list) {
          if (this.list[j].instrument_token == data[i].it) {
            this.list[j].ltp = data[i].ltp;
            this.list[j].ltpc = data[i].ltpc;
            if (this.list[j].net_qty != 0) {
              this.list[j].m2m =
                this.$helperService.getFormattedPrice(
                  this.list[j].ltp - this.list[j].bea
                ) * this.list[j].net_qty;
            } else {
              this.list[j].m2m =
                this.list[j].total_sell_quantity * this.list[j].avg_sell_price -
                this.list[j].total_buy_quantity * this.list[j].avg_buy_price;
            }
          }
        }
        
        if (this.buySellObj && data[i].it == this.buySellObj.instrument_token) {
          this.buySellObj.a = data[i].a;
          this.buySellObj.ba = data[i].ba;
          this.buySellObj.ac = data[i].ac;
          this.buySellObj.b = data[i].b;
          this.buySellObj.bc = data[i].bc;
          this.buySellObj.a = data[i].a;
          this.buySellObj.ch = data[i].ch;
          this.buySellObj.h = data[i].h;
          this.buySellObj.l = data[i].l;
          this.buySellObj.o = data[i].o;
          this.buySellObj.c = data[i].c;
          this.buySellObj.ltp = data[i].ltp;
          this.buySellObj.ltpc = data[i].ltpc;
          this.buySellObj.n = data[i].n;
          this.buySellObj.ll = data[i].ll;
          this.buySellObj.ul = data[i].ul;
          this.buySellObj.lr = data[i].lr;
        }
      }
      this.excel_data = [];
      if (this.list.length > 0) {
        for (var k in this.list) {
          var obj = {};
          obj.exchange = this.list[k].script
            ? this.list[k].script.exchange == "NFO"
              ? "NSE"
              : "MCX"
            : "";
          obj.client = this.list[k].user
            ? this.$helperService.getUpperCase(
                this.list[k].user?.code + "-" + this.list[k].user?.server_code
              )
            : "";
          obj.expiry = this.list[k].expiry;
          obj.time = this.$helperService.getTime(this.list[k].created_at);
          obj.client = this.list[k].user
            ? this.$helperService.getUpperCase(
                this.list[k].user.name + "-" + this.list[k].user.code
              )
            : "";
          obj.trading_symbol = this.list[k].trading_symbol;
          obj.total_buy_quantity = this.$helperService.getFormattedPrice(
            parseInt(this.list[k].total_buy_quantity)
          );
          obj.avg_buy_price = this.$helperService.getFormattedPrice(
            parseInt(this.list[k].avg_buy_price)
          );
          obj.total_sell_quantity = this.$helperService.getFormattedPrice(
            parseInt(this.list[k].total_sell_quantity)
          );
          obj.avg_sell_price = this.$helperService.getFormattedPrice(
            parseInt(this.list[k].avg_sell_price)
          );
          obj.net_qty = this.list[k].net_qty;
          obj.be_avg = this.list[k].bea;
          obj.ltp = this.list[k].ltp;
          obj.mtm_profit = this.list[k].m2m;
          this.excel_data.push(obj);
        }
      }

      this.$refs.errorComponent?.updateFormLoader(false);
    },
    getList() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }
      if (this.id) {
        searchObj.user_id = this.id;
      }
       if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      // searchObj.day = "TODAY";
      this.$api
        .getAPI({
          _action: "/position-report-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          for (var k in this.list) {
            this.list[k].isChecked = false;
            if (this.list[k].net_qty != 0) {
              var avg =
                (this.list[k].total_sell_quantity *
                  this.list[k].avg_sell_price -
                  this.list[k].total_buy_quantity *
                    this.list[k].avg_buy_price) /
                this.list[k].net_qty;
              this.list[k].bea = Math.abs(avg);
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    showModal(id) {
      this.isShowModal = true;
      this.id = id;
      this.getList();
    },
    updateQuantity(lot) {
      this.buySellObj.quantity = lot * this.lot_size;

      var quantity = this.buySellObj.quantity;
      var max_quantity = Math.abs(this.buySellObj.net_qty);
      // alert(quantity + " " + max_quantity);
      if (quantity > max_quantity) {
        this.isQuantity = true;
        this.message =
          "Quantity Should be less then or equal to " + max_quantity;
      } else {
        this.isQuantity = false;
        this.message = "";
      }
    },
    updateLot() {
      this.buySellObj.lot_size = this.$helperService.getFormattedPrice(
        this.buySellObj.quantity / this.lot_size
      );
      var quantity = this.buySellObj.quantity;
      var max_quantity = Math.abs(this.buySellObj.net_qty);
      if (quantity > max_quantity) {
        this.isQuantity = true;
        this.message =
          "Quantity Should be less then or equal to " + max_quantity;
      } else {
        this.isQuantity = false;
        this.message = "";
      }
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
