<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <PageTitle title="MANAGE SCRIPTS" />
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 col-sm-12 row mt-3">
              <div class="col-lg-2 col-sm-6 mb-2">
                <Multiselect mode="single" trackBy="name" label="name" valueProp="value" class="register-select"
                  placeholder="MARKET" v-model="detail.exchange" :options="exchanges" searchable="true"
                  @select="getscriptList(detail.exchange)" />
              </div>
              <div class="col-lg-2 col-sm-6 mb-2">
                <Multiselect mode="single" trackBy="name" label="name" valueProp="name" class="register-select"
                  placeholder="SCRIPT" v-model="detail.script_name" :options="scriptList" searchable="true"
                  @change="getList(0)" @select="getExpireList(detail.script_name)" />
              </div>
              <div class="col-lg-2 col-sm-6 mb-2">
                <Multiselect mode="single" trackBy="expiry" label="expiry" valueProp="expiry" class="register-select"
                  placeholder="EXPIRY" v-model="detail.expiry" :options="expireList" searchable="true"
                  @select="getList(0)" />
              </div>

              <div class="row ml-2 p-3" style="
                  font-size: 12px;
                  font-weight: 550 !important;
                  margin-top: -18px;
                ">
                <input id="is_broker_gross" name="is_broker_gross" type="checkbox" v-model="detail.is_publish"
                  class="ml-1" :true-value="1" :false-value="0" />
                <label class="col-form-label" style="display: flex"><span class="ml-2">Publish </span></label>
              </div>
              <div class="col-lg-2 input-group mb-2 mr-sm-2 ml-3">
                <Field type="date" class="form-control" name="trade_date" style="
                    width: 150px;
                    height: 30px !important;
                    padding: 0px !important;
                    font-size: 12px;
                    word-spacing: 0px;
                    letter-spacing: 3px;
                  "  data-date-format="DD-MM-YYYY" v-model="detail.custom_expiry" placeholder="Trade Date" />
              </div>
              <div class="col-lg-1 input-group mb-2 mr-sm-2 ml-3">
                <button id="btn-search" type="button" style="width: 100px" v-on:click="updatescripts()"
                  class="btn btn-primary mb-2">
                  Add
                </button>
              </div>
              <div class="col-lg-1 input-group mb-2 mr-sm-2">
                <button id="btn-search" type="button" style="width: 120px" v-on:click="restartFeed()"
                  class="btn btn-warning mb-2">
                  Restart Feed
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row" style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-lg-12 col-sm-12 row">
            <div class="col-lg-2 input-group mr-sm-2">
              <Field type="text" class="form-control" name="Search" placeholder="Search" v-model="keyword"
                v-on:keyup="getList()" />
            </div>
            <div class="col-lg-2 input-group mr-sm-2">
              <button id="btn-search" type="button" v-on:click="getList(1)" class="btn btn-primary mb-2">
                Search
              </button>
              &nbsp;
              <button type="button" v-on:click="reset" class="btn btn-secondary mb-2">
                Reset
              </button>
            </div>
          </div>
          <table id="table" style="margin-top: 9px" data-toggle="table" data-search="true" data-filter-control="true"
            data-toolbar="#toolbar" class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Sr No</th>
                <th class="head sorting" id="id-token" v-on:click="sorting('scripts.instrument_token', 'id-token')">
                  Intrument Token
                </th>
                <th class="head sorting" id="id-name" v-on:click="sorting('scripts.name', 'id-name')">
                  Name
                </th>
                <th class="head sorting" id="id-symbol" v-on:click="sorting('scripts.trading_symbol', 'id-symbol')">
                  Symbol
                </th>
                <th class="head sorting" id="id-symbol" v-on:click="sorting('scripts.expiry', 'id-symbol')">
                  Expiry
                </th>
                <th class="head sorting" id="id-symbol" v-on:click="
                  sorting('custum_scripts.custom_expiry', 'id-symbol')
                  ">
                  Custom Expiry
                </th>
                <th class="head sorting" id="id-Size" v-on:click="sorting('custum_scripts.lot_size', 'id-Size')">
                  Lot Size
                </th>
                <th class="head">Publish</th>
                <th class="head">Is Split/Div</th>
                <th class="head">Action</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body">{{ getSrNo(index) }}</td>
                <td class="body">{{ data.instrument_token }}</td>
                <td class="body">{{ data.name }}</td>
                <td class="body">{{ data.trading_symbol }}</td>
                <td class="body">{{ $helperService.getDate(data.expiry) }}</td>
                <td class="body" v-if="data.isEdit == false">
                  {{
                    data.custom_expiry
                    ? $helperService.getDate(data.custom_expiry)
                    : "-"
                  }}
                </td>
                <td class="body" v-if="data.isEdit == true">
                  <div style="display: block ruby">
                    <Field type="date" class="form-control" name="trade_date" style="
                        width: 150px;
                        height: 25px !important;
                        padding: 0px !important;
                        font-size: 12px;
                        word-spacing: 0px;
                        letter-spacing: 3px;
                      " v-model="data.custom_expiry" placeholder="Trade Date" />
                  </div>
                </td>
                <td class="body">
                  {{ data.lot_size }}
                </td>
                <td class="body" style="display: block ruby">
                  <input type="checkbox" :disabled="data.isEdit == false" name="is_publish"
                    style="width: 15px; height: 20px !important" v-model="data.is_publish" true-value="1"
                    false-value="0" />
                </td>
                <td class="body">
                  <input type="checkbox" :disabled="data.isEdit == false" name="is_split_divident"
                    style="width: 15px; height: 20px !important" v-model="data.is_split_divident" true-value="1"
                    false-value="0" />
                </td>
                <td class="body">
                  <i v-if="data.isEdit == false" v-on:click="editRow(index)" class="fa fa-pencil-square-o mr-2"
                    aria-hidden="true" style="cursor: pointer; color: rgb(29, 68, 91)"></i>
                  <i v-if="data.isEdit == true" v-on:click="updateRow(index)" class="fa fa-check mr-2" aria-hidden="true"
                    style="cursor: pointer; color: green"></i>
                  <i v-if="data.isEdit == true" v-on:click="closeIsEdit()" class="fa fa-times mr-2" aria-hidden="true"
                    style="cursor: pointer; color: red"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" @get-list="getList(0)" ref="deleteItem" />
  </div>
</template>
<script>
import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
import Multiselect from "@vueform/multiselect";
import PageTitle from '../../components/PageTitle'
import moment from "moment";
export default {
  components: {
    ErrorComponent,
    Field,
    DeleteModal,
    Pagination,
    PageTitle,
    Multiselect,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      list: [],
      expireList: [],
      scriptList: [],
      detail: {},
      keyword: "",
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },
      ],
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    editRow(index) {
      for (var i in this.list) {
        if (i == index) {
          this.list[i].isEdit = true;
        } else {
          this.list[i].isEdit = false;
        }
      }
    },
    closeIsEdit() {
      for (var i in this.list) {
        this.list[i].isEdit = false;
      }
    },
    updateRow(index) {
      for (var i in this.list) {
        if (index == i) {
          this.list[i].isEdit = false;
          var obj = this.list[i];
        }
      }
      this.$api
        .putAPI({
          _action: "/update-script",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
        })
        .catch(() => { });
    },
    updatescripts() {
      this.detail.lot_size = 0;
      this.$api
        .putAPI({
          _action: "/update-scripts",
          _body: this.detail,
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.getList(0);
        })
        .catch(() => { });
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(this.id, obj.order_by, obj.sort_by);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }

      if (this.detail.exchange) {
        searchObj.exchange = this.detail.exchange;
      }
      if (this.detail.expiry) {
        searchObj.expiry = this.detail.expiry;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }

      searchObj.offset = page;
      searchObj.limit = 50;
      this.$api
        .getAPI({
          _action: "/script-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          for (var i in this.list) {
            this.list[i].isEdit = false;
          }
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 50);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getExpireList(name) {
      var obj = {};
      if (name) {
        obj.name = name;
      }
      if (this.detail.exchange) {
        obj.exchange = this.detail.exchange;
      }
      this.$api
        .getAPI({
          _action: "/expire-list-segment-new",
          _body: obj,
        })
        .then((res) => {
          this.expireList = res;
          for (var i in this.expireList) {
            if (this.expireList[i].expiry) {
              this.expireList[i].expiry = moment(
                this.expireList[i].expiry
              ).format("DD-MM-YYYY");
            }
          }
          this.getList(0);
        })
        .catch(() => { });
    },
    getscriptList(exchange) {
      var obj = {};
      obj.exchange = exchange;
      obj.sort_by = "name";
      obj.order_by = "ASC";

      if (exchange) {
        this.$api
          .getAPI({
            _action: "/master-script-list-v2",
            _body: obj,
          })
          .then((res) => {
            this.scriptList = res.list;
            this.getList(0);
            this.getExpireList();
          })
          .catch(() => { });
      }
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 50 + (index + 1)
        : index + 1;
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Broker",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/account/" + detail.id);
    },
    restartFeed() {
      this.$api
        .getAPI({
          _action:
            "/feed-restart",
          _isExternal: true,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => { });
    }
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
