<template>
  <div>
    <div class="container form-signin3">
      <div class="card-bg col-md-12 dealer-form form-signin3 mt-0">
        <div class="container">
          <div class="border-page">
            <Form ref="account" @submit="save()">
              <div class="col-12 form row mb-2">
                <div class="col-12">
                  <label class="col-form-label" style="display: flex">
                    API KEY
                  </label>
                  <textarea
                    style="height: auto !important"
                    class="form-control"
                    name="api_key"
                    placeholder="Api Key"
                    v-model="details.api_key"
                  />
                </div>

                <div class="col-12">
                  <label class="col-form-label" style="display: flex">
                    HEADER MESSAGE
                  </label>
                  <textarea
                    style="height: auto !important"
                    class="form-control"
                    name="header_message"
                    placeholder="Header Message"
                    v-model="details.header_message"
                  />
                </div>

                <div class="col-12 mt-2 mb-2" style="color: white">
                  <hr class="dropdown-divider-new" />
                  <h5 style="text-decoration: underline">
                    MARKET START-END TIMMINGS
                  </h5>
                </div>
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    NSE START TIME
                  </label>
                  <datepicker
                    v-model="details.nse_start_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    NSE END TIME
                  </label>
                  <datepicker
                    v-model="details.nse_end_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    MCX START TIME
                  </label>
                  <datepicker
                    v-model="details.mcx_start_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    MCX END TIME
                  </label>
                  <datepicker
                    v-model="details.mcx_end_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>

                <div class="col-12 mt-2 mb-2" style="color: white">
                  <hr class="dropdown-divider-new" />
                  <h5 style="text-decoration: underline">INTRADAY-TIMMINGS</h5>
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    NSE INTRADAY ORDER STOP TIME
                  </label>
                  <datepicker
                    v-model="details.nse_intraday_order_stop_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    NSE INTRADAY SQUARE OFF TIME
                  </label>
                  <datepicker
                    v-model="details.nse_intraday_square_off_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    MCX INTRADAY ORDER STOP TIME
                  </label>
                  <datepicker
                    v-model="details.mcx_intraday_order_stop_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    MCX INTRADAY SQUARE OFFTIME
                  </label>
                  <datepicker
                    v-model="details.mcx_intraday_square_off_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>

                <div class="col-12 mt-2 mb-2 row" style="color: white">
                  <div class="col-6">
                    <hr class="dropdown-divider-new" />
                    <h5 style="text-decoration: underline">
                      EXPIRE-SQUARE-OFF-TIME
                    </h5>
                  </div>
                  <div class="col-6">
                    <hr class="dropdown-divider-new" />
                    <h5 style="text-decoration: underline">
                      DELETE-PENDING-ORDER-TIME
                    </h5>
                  </div>
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    NSE EXPIRY SQUARE OFF TIME
                  </label>
                  <datepicker
                    v-model="details.nse_expire_square_off_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    MCX EXPIRY SQUARE OFF TIME
                  </label>
                  <datepicker
                    v-model="details.mcx_expire_square_off_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    NSE DELETE PENDING ORDER TIME
                  </label>
                  <datepicker
                    v-model="details.nse_delete_pending_order_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    MCX DELETE PENDING ORDER TIME
                  </label>
                  <datepicker
                    v-model="details.mcx_delete_pending_order_time"
                    enable-seconds
                    seconds-increment="1"
                    autoApply
                    :is-24="true"
                    placeholder="Select Time"
                    timePicker
                  />
                </div>

                <div class="col-12 mt-2 mb-2 row" style="color: white">
                  <div class="col-6">
                    <hr class="dropdown-divider-new" />
                    <h5 style="text-decoration: underline">
                      PASS-LIMIT-SETTING
                    </h5>
                  </div>
                  <div class="col-6 row"></div>
                </div>
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    NSE Pass Limit
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="nse_pass_limit"
                    class="register-select"
                    v-model="details.nse_pass_limit"
                    as="select"
                    rules="selectRequired:role"
                    :validateOnInput="true"
                  >
                    <option value="" disabled>NSE Pass Limit</option>
                    <option
                      v-for="(data, index) in Nselist"
                      :key="index"
                      :value="data.value"
                    >
                      {{ data.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="nse_pass_limit" class="text-danger" />
                </div>
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    MCX Pass Limit
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="mcx_pass_limit"
                    class="register-select"
                    v-model="details.mcx_pass_limit"
                    as="select"
                    rules="selectRequired:role"
                    :validateOnInput="true"
                  >
                    <option value="" disabled>MCX Pass Limit</option>
                    <option
                      v-for="(data, index) in Mcxlist"
                      :key="index"
                      :value="data.value"
                    >
                      {{ data.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="mcx_pass_limit" class="text-danger" />
                </div>
                <div class="col-md-12" style="display: flex">
                  <button
                    class="save-btn px-4 ml-3"
                    type="submit"
                    id="save-button"
                  >
                    Save
                  </button>
                </div>
              </div>
              <div class="col-md-12 text-right"></div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetails()" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    Datepicker,
  },
  name: "settings",
  data() {
    return {
      Mcxlist: [
        {
          name: "LTP",
          value: "LTP",
        },
        {
          name: "Buyer / Seller",
          value: "BUYER_SELLER",
        },
      ],
      Nselist: [
        {
          name: "LTP",
          value: "LTP",
        },
        {
          name: "Buyer / Seller",
          value: "BUYER_SELLER",
        },
      ],
      details: {
        nse_start_time: "",
        nse_end_time: "",
        mcx_start_time: "",
        mcx_end_time: "",
        nse_pass_limit: "",
        mcx_pass_limit: "",
        mcx_intraday_square_off_time: "",
        mcx_intraday_order_stop_time: "",
        mcx_expire_square_off_time: "",
        mcx_delete_pending_order_time: "",
        nse_intraday_square_off_time: "",
        nse_intraday_order_stop_time: "",
        nse_expire_square_off_time: "",
        nse_delete_pending_order_time: "",
      },
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/settings",
        })
        .then((res) => {
          if (res.nse_start_time) {
            var time1 = res.nse_start_time.split(":");
            res.nse_start_time = {
              hours: time1[0],
              minutes: time1[1],
              seconds: time1[2],
            };
          }
          if (res.nse_end_time) {
            var time2 = res.nse_end_time.split(":");
            res.nse_end_time = {
              hours: time2[0],
              minutes: time2[1],
              seconds: time2[2],
            };
          }
          if (res.mcx_start_time) {
            var time3 = res.mcx_start_time.split(":");
            res.mcx_start_time = {
              hours: time3[0],
              minutes: time3[1],
              seconds: time3[2],
            };
          }
          if (res.mcx_end_time) {
            var time4 = res.mcx_end_time.split(":");
            res.mcx_end_time = {
              hours: time4[0],
              minutes: time4[1],
              seconds: time4[2],
            };
          }
          if (res.mcx_delete_pending_order_time) {
            var time5 = res.mcx_delete_pending_order_time.split(":");
            res.mcx_delete_pending_order_time = {
              hours: time5[0],
              minutes: time5[1],
              seconds: time5[2],
            };
          }
          if (res.nse_delete_pending_order_time) {
            var time65 = res.nse_delete_pending_order_time.split(":");
            res.nse_delete_pending_order_time = {
              hours: time65[0],
              minutes: time65[1],
              seconds: time65[2],
            };
          }

          if (res.mcx_expire_square_off_time) {
            var time6 = res.mcx_expire_square_off_time.split(":");
            res.mcx_expire_square_off_time = {
              hours: time6[0],
              minutes: time6[1],
              seconds: time6[2],
            };
          }

          if (res.nse_expire_square_off_time) {
            var time61 = res.nse_expire_square_off_time.split(":");
            res.nse_expire_square_off_time = {
              hours: time61[0],
              minutes: time61[1],
              seconds: time61[2],
            };
          }

          if (res.mcx_intraday_order_stop_time) {
            var time7 = res.mcx_intraday_order_stop_time.split(":");
            res.mcx_intraday_order_stop_time = {
              hours: time7[0],
              minutes: time7[1],
              seconds: time7[2],
            };
          }

          if (res.nse_intraday_order_stop_time) {
            var time71 = res.nse_intraday_order_stop_time.split(":");
            res.nse_intraday_order_stop_time = {
              hours: time71[0],
              minutes: time71[1],
              seconds: time71[2],
            };
          }

          if (res.mcx_intraday_square_off_time) {
            var time8 = res.mcx_intraday_square_off_time.split(":");
            res.mcx_intraday_square_off_time = {
              hours: time8[0],
              minutes: time8[1],
              seconds: time8[2],
            };
          }

          if (res.nse_intraday_square_off_time) {
            var time81 = res.nse_intraday_square_off_time.split(":");
            res.nse_intraday_square_off_time = {
              hours: time81[0],
              minutes: time81[1],
              seconds: time81[2],
            };
          }

          this.details = res;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    save() {
      if (this.details.nse_start_time) {
        this.details.nse_start_time = moment(
          this.details.nse_start_time
        ).format("HH:mm:ss");
      }
      if (this.details.nse_end_time) {
        this.details.nse_end_time = moment(this.details.nse_end_time).format(
          "HH:mm:ss"
        );
      }
      if (this.details.mcx_start_time) {
        this.details.mcx_start_time = moment(
          this.details.mcx_start_time
        ).format("HH:mm:ss");
      }
      if (this.details.mcx_end_time) {
        this.details.mcx_end_time = moment(this.details.mcx_end_time).format(
          "HH:mm:ss"
        );
      }
      if (this.details.mcx_delete_pending_order_time) {
        this.details.mcx_delete_pending_order_time = moment(
          this.details.mcx_delete_pending_order_time
        ).format("HH:mm:ss");
      }

      if (this.details.nse_delete_pending_order_time) {
        this.details.nse_delete_pending_order_time = moment(
          this.details.nse_delete_pending_order_time
        ).format("HH:mm:ss");
      }
      if (this.details.nse_expire_square_off_time) {
        this.details.nse_expire_square_off_time = moment(
          this.details.nse_expire_square_off_time
        ).format("HH:mm:ss");
      }

      if (this.details.mcx_expire_square_off_time) {
        this.details.mcx_expire_square_off_time = moment(
          this.details.mcx_expire_square_off_time
        ).format("HH:mm:ss");
      }
      if (this.details.nse_intraday_order_stop_time) {
        this.details.nse_intraday_order_stop_time = moment(
          this.details.nse_intraday_order_stop_time
        ).format("HH:mm:ss");
      }
      if (this.details.mcx_intraday_order_stop_time) {
        this.details.mcx_intraday_order_stop_time = moment(
          this.details.mcx_intraday_order_stop_time
        ).format("HH:mm:ss");
      }
      if (this.details.nse_intraday_square_off_time) {
        this.details.nse_intraday_square_off_time = moment(
          this.details.nse_intraday_square_off_time
        ).format("HH:mm:ss");
      }

      if (this.details.mcx_intraday_square_off_time) {
        this.details.mcx_intraday_square_off_time = moment(
          this.details.mcx_intraday_square_off_time
        ).format("HH:mm:ss");
      }
      this.$api
        .putAPI({
          _action: "/settings",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.getDetails();
          document.getElementById("appMarquee").innerText =
            this.details.header_message;
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
