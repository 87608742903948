<template>
  <div>
    <div class="container form-signin3">
      <div class="card-bg col-md-12 dealer-form form-signin3 mt-0">
        <h2 class="text-start" style="color: white; font-weight: 600">
          Send Notification
        </h2>
        <div class="container">
          <div class="border-page">
            <Form ref="account" @submit="save()">
              <div class="col-12 form mb-2 row">
                <div
                  class="col-4"
                  style="text-align: left"
                  v-if="role == 'ADMIN'"
                >
                  <label class="col-form-label" style="display: flex">
                    Super Master
                    <span class="text-danger">*</span>
                  </label>
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="server_code"
                    class="register-select"
                    placeholder=" Super Master"
                    v-model="detail.server_code"
                    :options="supermasterList"
                    @select="getMasterList()"
                    searchable="true"
                  />
                </div>
                <div class="col-4" style="text-align: left">
                  <label class="col-form-label" style="display: flex">
                    Master
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    class="register-select"
                    placeholder="Master"
                    v-model="detail.master_id"
                    :options="masterList"
                    @select="getToClientList()"
                    searchable="true"
                  />
                </div>
                <div class="col-12">
                  <label class="col-form-label" style="display: flex">
                    To Client
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <Multiselect
                    mode="tags"
                    trackBy="new_name"
                    label="new_name"
                    valueProp="id"
                    class="register-select"
                    placeholder="To Client"
                    v-model="detail.user_id"
                    :options="client_list"
                    @change="getToClientList()"
                    searchable="true"
                  />
                </div>
                <div class="col-12">
                  <label class="col-form-label" style="display: flex">
                    Message
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <textarea
                    style="height: auto !important"
                    type="text"
                    class="form-control"
                    name="note"
                    placeholder="Message"
                    v-model="detail.title"
                  />
                </div>

                <div class="col-md-12" style="display: flex">
                  <button
                    class="save-btn px-4 ml-3"
                    type="submit"
                    id="save-button"
                  >
                    Send
                  </button>
                </div>
              </div>
              <div class="col-md-12 text-right"></div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetails()" ref="errorComponent" />
  </div>
</template>
<script>
import { Form } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Form,
    ErrorComponent,
    Multiselect,
  },
  watch: {
    "$route.fullPath"() {
      this.$refs.account.resetForm();
      this.id = "";
    },
  },
  name: "Profile",
  data() {
    return {
      role: localStorage.getItem("role"),
      detail: {
        user_id: [],
        master_id: "",
        server_code: "",
      },
      client_list: [],
      supermasterList: [],
      masterList: [],
    };
  },

  mounted() {
    // this.getClientList();
    this.getSuperMasterList();
    this.getMasterList("FIRST");
  },
  methods: {
    getSuperMasterList() {
      this.$api
        .getAPI({
          _action: "/super-master-list",
        })
        .then((res) => {
          this.supermasterList = res;
        })
        .catch(() => {});
    },
    getMasterList(type) {
      this.detail.master_id = "";
      this.detail.user_id = [];
      this.masterList = [];
      var obj = {};
      if (this.detail.server_code) {
        obj.server_code = this.detail.server_code;
      }
      this.$api
        .getAPI({
          _action: "/all-master-list",
          _body: obj,
        })
        .then((res) => {
          this.masterList = res;
          if (!type) {
            this.getToClientList();
          }
        })
        .catch(() => {});
    },
    getToClientList() {
      this.client_list = [];
      this.detail.user_id = [];
      var obj = {};
      if (this.detail.server_code) {
        obj.server_code = this.detail.server_code;
      }
      if (this.detail.master_id) {
        obj.master_id = this.detail.master_id;
      }
      this.$api
        .getAPI({
          _action: "/all-client-list",
          _body: obj,
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + " (" + this.client_list[i].code + ")";
          }
        })
        .catch(() => {});
    },

    save() {
      this.$api
        .postAPI({
          _action: "/add-message",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
            this.$router.push("/master-notifications");
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
