<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h2
            class="text-start"
            style="color: white; font-weight: 600; font-size: 16px">
            Ledger For {{ name }}
          </h2>
          <form class="form-inline transparent-form p10 border-page mt-2">
            <div class="col-lg-12 row">
              <div class="col-lg-3">
                <Field
                  type="text"
                  class="form-control"
                  name="ledger_balance"
                  placeholder="Search"
                  v-model="keyword"
                  v-on:keyup="getList()" />
              </div>
              <div class="col-lg-9 text-right">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="PayInOut('PAY IN')"
                  class="btn btn-primary mb-2 mr-2">
                  Pay In
                </button>
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="PayInOut('PAY OUT')"
                  class="btn btn-primary mb-2 mr-2">
                  Pay Out
                </button>
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList(1)"
                  class="btn btn-primary mb-2">
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Sr.No</th>
                <th class="head">Name</th>
                <th class="head">Date Time</th>
                <th class="head">Debit</th>
                <th class="head">Credit</th>
                <th class="head">Balance</th>
                <th class="head">PDF</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr
                v-for="(data, index) in list"
                :key="index"
                style="cursor: pointer">
                <td
                  class="body"
                  style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  v-on:click="
                    $router.push(
                      '/user-settle-trades/' +
                        (this.name + ' ' + data.remark) +
                        '/' +
                        data.id
                    )
                  ">
                  {{ data.id }}
                </td>
                <td
                  class="body"
                  style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  v-on:click="
                    $router.push(
                      '/user-settle-trades/' +
                        (this.name + ' ' + data.remark) +
                        '/' +
                        data.id
                    )
                  ">
                  {{ data.remark ? data.remark : "" }}
                  {{
                    data.created_by
                      ? "( " +
                        data.created_by.name +
                        "  " +
                        "( " +
                        data.created_by.code +
                        " )" +
                        " )"
                      : ""
                  }}
                  {{ data.ip ? "( " + data.ip + " )" : "" }}
                </td>
                <td
                  class="body"
                  style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  v-on:click="
                    $router.push(
                      '/user-settle-trades/' +
                        (this.name + ' ' + data.remark) +
                        '/' +
                        data.id
                    )
                  ">
                  {{
                    data.created_at
                      ? $helperService.getDateTime(data.created_at)
                      : ""
                  }}
                </td>
                <td
                  class="body"
                  style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  :style="
                    data.debit < 0
                      ? 'color: red !important'
                      : 'color: blue !important'
                  ">
                  {{
                    data.debit
                      ? $helperService.getFormattedPrice(data.debit)
                      : 0
                  }}
                </td>

                <td
                  class="body"
                  style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  :style="
                    data.credit < 0
                      ? 'color: red !important'
                      : 'color: blue !important'
                  "
                  v-on:click="
                    $router.push(
                      '/user-settle-trades/' +
                        (this.name + ' ' + data.remark) +
                        '/' +
                        data.id
                    )
                  ">
                  {{
                    data.credit
                      ? $helperService.getFormattedPrice(data.credit)
                      : 0
                  }}
                </td>

                <td
                  class="body"
                  style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  :style="
                    data.balance < 0
                      ? 'color: red !important'
                      : 'color: blue !important'
                  "
                  v-on:click="
                    $router.push(
                      '/user-settle-trades/' +
                        (this.name + ' ' + data.remark) +
                        '/' +
                        data.id
                    )
                  ">
                  {{
                    data.balance
                      ? $helperService.getFormattedPrice(data.balance)
                      : 0
                  }}
                </td>

                <td class="body">
                  <a
                    v-if="data.remark != 'PAY OUT' && data.remark != 'PAY IN'"
                    style="margin-left: 10px"
                    v-on:click="download(data)"
                    ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td colspan="3"></td>
                <td
                  class="body"
                  style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  :style="
                    debit < 0
                      ? 'color: red !important'
                      : 'color: blue !important'
                  ">
                  {{ debit.toFixed(2) }}
                </td>
                <td
                  class="body"
                  style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  :style="
                    credit < 0
                      ? 'color: red !important'
                      : 'color: blue !important'
                  ">
                  {{ credit.toFixed(2) }}
                </td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PrintSettlement ref="printSettlement"></PrintSettlement>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem" />
    <TextModal ref="payInOut">
      <div class="modal-header pt-0">
        <h5 class="modal-title">{{ Paytype }}</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.payInOut.closeModal">
          <span aria-hidden="true" style="color: white;">&times;</span>
        </button>
      </div>
      <Form ref="payInOutForm" @submit="payInOutApi()">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Amount</label>
            <span class="text-danger">*</span>
            <Field
              type="text"
              name="Amount"
              class="form-control"
              placeholder="Amount"
              v-model="amount"
              rules="required"
              v-on:keypress="$helperService.allowDecimalValue($event)"
              :validateOnInput="true" />
            <span
              style="
                text-align: left;
                color: black;
                font-size: 13px;
                margin-top: 1%;
                display: flex;
              ">
              {{ $helperService.convert(amount) }}</span
            >
            <ErrorMessage name="Amount" class="text-danger" />
          </div>
        </div>
        <div class="pb-0 text-center">
          <button class="save-btn" id="submit-pay-in-out">Submit</button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal.vue";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment";
import PrintSettlement from "../../components/PrintSettlement.vue";
export default {
  components: {
    ErrorComponent,
    PrintSettlement,
    Form,
    Field,
    ErrorMessage,
    DeleteModal,
    Pagination,
    TextModal,
  },
  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
      name: this.$route.params.name,
      usertype: this.$route.params.type,
      loader: true,
      type: "Default",
      list: [],
      Paytype: "",
      fromDate: "",
      toDate: "",
      debit: 0,
      credit: 0,
      amount: "",
      supermasterList: [],
    };
  },
  computed: {
    isSettlement() {
      var isSettlment = false;
      if (this.supermasterList) {
        for (var obj of this.supermasterList) {
          console.log(
            obj.server_code + "   " + this.$store.getters.getUser?.server_code
          );
          if (obj.server_code == this.$store.getters.getUser?.server_code) {
            if (obj.is_settlement == 1) {
              isSettlment = true;
            }
          }
        }
      }
      return isSettlment;
    },
  },
  mounted() {
    this.getSuperMasterList();
    this.getList(0);
  },
  methods: {
    getSuperMasterList() {
      this.$api
        .getAPI({
          _action: "/super-master-list",
        })
        .then((res) => {
          this.supermasterList = res;
        })
        .catch(() => {});
    },
    PayInOut(type) {
      this.Paytype = type;
      this.amount = "";
      this.$refs.payInOutForm && this.$refs.payInOutForm.resetForm();
      this.$refs.payInOut.showModal();
    },
    payInOutApi() {
      var obj = {};
      obj.user_id = this.id;
      obj.type = this.Paytype;
      obj.amount = this.amount;
      this.$api
        .postAPI({
          _action: "/pay-in-out",
          _body: obj,
          _buttonId: "submit-pay-in-out",
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.$refs.payInOut.closeModal();
          this.getList(1);
        })
        .catch(() => {});
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    getList() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }

      if (this.id) {
        searchObj.id = this.id;
      }
      if (this.usertype) {
        searchObj.type = this.usertype;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }

      this.debit = 0;
      this.credit = 0;
      this.$api
        .getAPI({
          _action: "/user-settlement-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;

          if (this.$refs.errorComponent && res.list.length <= 0) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          } else {
            for (var i in res.list) {
              if (i == 0) {
                res.list[i].balance = 0;
              } else {
                res.list[i].balance = res.list[i - 1].balance;
              }

              res.list[i].balance += res.list[i].debit ? res.list[i].debit : 0;
              this.debit += res.list[i].debit ? res.list[i].debit : 0;
              this.credit += res.list[i].credit ? res.list[i].credit : 0;
              res.list[i].balance += res.list[i].credit
                ? res.list[i].credit
                : 0;
            }
            this.$refs.errorComponent.updateFormLoader(false);
            this.list = res.list;
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },

    download(data) {
      this.$refs.errorComponent.updateFormLoader(true);
      var searchObj = {};
      if (this.id) {
        searchObj.id = data.id;
      }
      this.$api
        .getAPI({
          _action: "/user-settle-trades-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;

          if (this.$refs.errorComponent && res.list.length <= 0) {
            alert("No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          } else {
            var tempList = [];
            for (var i in res.list) {
              res.list[i].datatype = "DATA";
              var k = -1;
              for (var j in tempList) {
                if (
                  tempList[j].trading_symbol.toUpperCase() ==
                  res.list[i].trading_symbol.toUpperCase()
                ) {
                  k = j;
                }
              }

              var netamount = res.list[i].net_price * res.list[i].quantity;
              if (res.list[i].order_action == "BUY") {
                netamount = -netamount;
              }
              var net_price = Math.abs(
                res.list[i].net_price * res.list[i].quantity -
                  res.list[i].price * res.list[i].quantity
              );
              if (k == -1) {
                var temp = {
                  trading_symbol: res.list[i].trading_symbol,
                  list: [res.list[i]],
                  bq:
                    res.list[i].order_action == "BUY"
                      ? res.list[i].quantity
                      : 0,
                  sq:
                    res.list[i].order_action == "SELL"
                      ? res.list[i].quantity
                      : 0,
                  price:
                    res.list[i].order_action == "BUY"
                      ? -res.list[i].price * res.list[i].quantity
                      : res.list[i].price * res.list[i].quantity,
                  net_price: net_price,
                  netamount: netamount,
                };
                tempList.push(temp);
              } else {
                tempList[k].bq +=
                  res.list[i].order_action == "BUY" ? res.list[i].quantity : 0;
                tempList[k].sq +=
                  res.list[i].order_action == "SELL" ? res.list[i].quantity : 0;
                if (res.list[i].order_action == "SELL") {
                  tempList[k].price += res.list[i].price * res.list[i].quantity;
                } else {
                  tempList[k].price -= res.list[i].price * res.list[i].quantity;
                }

                tempList[k].net_price += net_price;
                tempList[k].netamount += netamount;
                tempList[k].list.push(res.list[i]);
              }
            }

            for (var m in tempList) {
              var obj = {
                datatype: "Total",
                title: "Script Wise total:",
                bq: tempList[m].bq,
                sq: tempList[m].sq,
                price: tempList[m].price,
                net_price: Math.round(tempList[m].net_price),
                netamount: tempList[m].netamount,
              };

              tempList[m].list.push(obj);
            }

            var name =
              (res.user_detail.name
                ? this.$helperService.getUpperCase(res.user_detail.name)
                : "") +
              (res.user_detail.code
                ? "(" +
                  this.$helperService.getUpperCase(res.user_detail.code) +
                  ")"
                : "");

            this.$refs.printSettlement.setDataAndPrint(
              tempList,
              name,
              res.week_Detail?.remark
            );
            this.loader = false;
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
  },
};
</script>
