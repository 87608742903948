<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <!-- <h2
            class="text-start"
            style="color: white; font-weight: 600"
          >
            Default
          </h2> -->
          <Form
            class="form-inline transparent-form p10 border-page m-t-2"
            ref="account"
            @submit="save()"
          >
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                >
                  Client
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder=" Client"
                  v-model="detail.user_id"
                  :options="client_list"
                  @select="getList()"
                  searchable="true"
                />
              </div>
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                >
                  Segment
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder=" Exchange"
                  v-model="detail.exchange"
                  :options="exchanges"
                  @select="getScriptList()"
                  searchable="true"
                />
              </div>
              <div class="col-lg-2 col-sm-2">
                <label
                  class="col-form-label"
                  style="display: flex; justify-content: flex-start"
                >
                  Symbol
                  <span class="text-danger">*</span>
                </label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="name"
                  class="register-select"
                  placeholder=" Script"
                  v-model="detail.instrument_token"
                  :options="script_list"
                  searchable="true"
                />
              </div>
              <div class="col-lg-1 col-sm-1 mt-3">
                <!-- <div class="custom-control custom-radio mb-1">
                  <input
                    class="custom-control-input"
                    id="radio_101"
                    name="settingType"
                    type="radio"
                    v-model="detail.script_status"
                    value="ALLOW"
                  /><label class="custom-control-label" for="radio_101"
                    >Allow</label
                  >
                </div> -->
                <div class="custom-control custom-radio mb-1 mt-3">
                  <input
                    class="custom-control-input"
                    id="radio_102"
                    name="settingType"
                    v-model="detail.script_status"
                    type="radio"
                    value="BLOCK"
                  /><label class="custom-control-label" for="radio_102"
                    >Block</label
                  >
                </div>
              </div>
              <div class="col-lg-2 col-sm-2 mt-4">
                <button
                  class="save-btn green-btn"
                  style="width: 85px !important; margin-top: 0 !important"
                  id="save-button"
                >
                  ADD
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px"
      >
        <div class="col-md-12 bg-light border-page">
          <div class="col-12 row">
            <div class="col-lg-6">
              <Field
                type="text"
                class="col-lg-3 form-control"
                name="ledger_balance"
                placeholder="SEARCH"
                v-model="keyword"
                v-on:keyup="getList()"
              />
            </div>
            <div class="col-lg-6 text-right">
              <button
                v-on:click="deleteSelecOne()"
                class="delete-btn mr-2 cursor-pointer"
                style="
                  width: 85px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                "
                id="delete-button"
                type="button"
              >
                DELETE
              </button>
            </div>
          </div>
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover"
          >
            <thead>
              <tr>
                <th class="head">
                  <div class="switchToggleRead" style="text-align: start">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      :true-value="true"
                      :false-value="false"
                      v-on:change="checkall(isChecked)"
                    />
                    Is Delete
                  </div>
                </th>
                <th
                  class="head sorting"
                  id="id-Client"
                  v-on:click="sorting('users.name', 'id-Client')"
                >
                  Client Name
                </th>
                <th
                  class="head sorting"
                  id="id-exchange"
                  v-on:click="sorting('exchange', 'id-exchange')"
                >
                  Segment
                </th>
                <th
                  class="head sorting"
                  id="id-scripts"
                  v-on:click="sorting('instrument_token', 'id-scripts')"
                >
                  Symbol
                </th>
                <th
                  class="head sorting"
                  id="id-script_status"
                  v-on:click="sorting('script_status', 'id-script_status')"
                >
                  Type
                </th>
                <th
                  class="head sorting"
                  id="id-created_at"
                  v-on:click="sorting('created_at', 'id-created_at')"
                >
                  Add Time
                </th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body">
                  <div class="switchToggleRead" style="text-align: start">
                    <input
                      :id="'index_' + data.id"
                      type="checkbox"
                      v-model="data.isChecked"
                      v-on:change="checkHandlling(data.isChecked)"
                      :true-value="true"
                      :false-value="false"
                    />
                  </div>
                </td>
                <td class="body">{{ data.user ? data.user.name : "" }}</td>
                <td class="body">{{ data.exchange }}</td>
                <td class="body">
                  {{ data.instrument_token }}
                </td>
                <td class="body">{{ data.script_status }}</td>
                <td class="body">
                  {{ $helperService.getDateTime(data.created_at) }}
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Form,
    Field,
    ErrorComponent,
    Multiselect,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      isChecked: false,
      type: "Default",
      keyword: "",
      list: [],
      detail: {
        exchange: "",
        instrument_token: "",
        script_status: "BLOCK",
      },
      client_list: [],
      script_list: [],
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },
      ],
    };
  },
  mounted() {
    this.checkAccess();
    this.getClientList();
  },
  methods: {
     checkAccess() {
      var access = JSON.parse(localStorage.getItem("access"));
      if (access.is_blockScript == 0) {
        this.$router.push("/access-denied");
      }
    },
    getScriptList() {
      this.script_list = [];

      this.$api
        .getAPI({
          _action: "/script-list/" + this.detail.exchange,
        })
        .then((res) => {
          this.script_list.push({ instrument_token: "ALL", name: "ALL" });
          for (var i in res) {
            this.script_list.push(res[i]);
          }
          this.getList();
        })
        .catch(() => {});
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list.push({ id: "ALL", new_name: "ALL" });
          for (var i in res) {
            res[i].new_name = res[i].name + " (" + res[i].code + ")";
            this.client_list.push(res[i]);
          }
          // for (var i in this.client_list) {
          //   this.client_list[i].new_name =
          //     this.client_list[i].name + " (" + this.client_list[i].code + ")";
          // }
        })
        .catch(() => {});
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          this.list[k].isChecked = true;
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = -1;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
        console.log("object", count);
        if (count == 0) {
          this.isChecked = true;
        }
      }
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },

    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    getList(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.detail.user_id) {
        searchObj.user_id = this.detail.user_id;
      }
      if (this.detail.exchange) {
        searchObj.exchange = this.detail.exchange;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/block-script-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          for (var i in this.list) {
            this.list[i].isChecked = false;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    save() {
      this.$api
        .postAPI({
          _action: "/block-script",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
            var user_id = this.detail.user_id;
            this.detail = {};
            this.detail.user_id = user_id;
            this.getList();
          }
        })
        .catch(() => {});
    },
    deleteSelecOne() {
      this.$refs.errorComponent.updateFormLoader(true);
      var array = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          array.push(this.list[i].id);
        }
      }
      var obj = {};
      obj.ids = array;

      if (array.length > 0) {
        this.$api
          .postAPI({
            _action: "/delete-block-setting",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              this.$refs.errorComponent.updateFormLoader(false);
              this.getList();
            }
          })
          .catch(() => {
            this.$refs.errorComponent.updateFormLoader(false);
          });
      } else {
        this.$notify({
          type: "error",
          text: "Select atleast on setting.",
        });
        this.$refs.errorComponent.updateFormLoader(false);
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
